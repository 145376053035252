import {
  EDIT_LAUNDRY,
  FETCH_LAUNDRY,
  FETCH_LAUNDRY_INIT,
  GET_DETACHEDS,
} from './PartnersActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  filters: {},
  paymentMethods: [],
  streetAttendants: [],
  weeks: [],
  editingLaundry: {
    id: '',
    name: '',
    company_name: '',
    contact_name: '',
    email: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    observations: '',
    neighborhood: '',
    city: '',
    uf: '',
    cnpj: '',
    phone: '',
    phone2: '',
    status: true,
    payment_method: '',
    payment_method_id: '',
    default_value: null,
    urgent_value: null,
    attendants: [],
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        meta: action.payload.data.meta,
        paymentMethods: action.payload.data.paymentMethods,
        streetAttendants: action.payload.data.streetAttendants,
        weeks: action.payload.data.weeks,
        filters: action.payload.filters,
      }
    case FETCH_LAUNDRY_INIT.SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload.data.paymentMethods,
        weeks: action.payload.data.weeks,
      }
    case EDIT_LAUNDRY.SUCCESS:
      return {
        ...state,
        editingLaundry: {
          ...action.payload.data,
        },
      }
    case GET_DETACHEDS.SUCCESS:
      return {
        ...state,
        appointments: action.payload.appointments,
      }
    default:
      return state
  }
}

export const getLaundryListData = state => {
  return {
    items: state.laundry.items,
    filters: state.laundry.filters,
  }
}

export const getEditingLaundry = state => {
  return state.laundry.editingLaundry
}
