/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../components/hooks/BaseTableComponents'
import {
  mdiAlert,
  mdiCalendarLock,
  mdiCash,
  mdiChevronDown,
  mdiClipboardTextClockOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiEmailFastOutline,
  mdiEyeOutline,
  mdiFilePdfBox,
  mdiNoteEditOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../../assets/colors'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import { exportFormatedMoney, renderRouteRole } from '../../../../util/utils'
import {
  closeLaundryOS,
  fetchAttendancePartner,
  reopenOS,
} from '../PartnerAttendanceActions'
import Pagination from '../../../../components/hooks/BasePagination'
// import { fetchLaundries, remove } from '../PartnersActions'

const useStyles = makeStyles(theme => ({
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
  missingProduct: {
    color: colors.error,
    gap: '.4em',
  },
  tooltip: {
    backgroundColor: colors.error,
    fontSize: '14px',
    boxShadow: '0px 0px 7px 0px #00000040',
    '& .arrow': {
      color: colors.error,
    },
  },
  status: {
    borderRadius: '5px',
    width: '82px',
    height: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFF',
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '12px',
    padding: '4px 0',
  },
}))

const List = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const items = useSelector(state => state.partnerAttendances.items)
  const meta = useSelector(state => state.partnerAttendances.meta)
  const filters = useSelector(state => state.partnerAttendances.filters)
  const role = useSelector(state => state.auth.role)

  const [open, setOpen] = useState({
    id: '',
  })

  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    setPaginate({
      ...paginate,
      page: newPage,
    })
  }

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const confirmDelete = (id, name) => {
    confirm({
      description: `Deseja deletar a lavanderia: ${name}?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  const closeOS = id => {
    confirm({
      description: 'Deseja fechar esta OS?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      // setModalOpen(true)
      // setCurrentID(id)
      dispatch(closeLaundryOS(id))
      dispatch(
        fetchAttendancePartner(filters, paginate.per_page, paginate.page)
      )
    })
  }

  const reopenOs = id => {
    confirm({
      description: 'Deseja reabrir esta OS?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      // setModalOpen(true)
      // setCurrentID(id)
      dispatch(reopenOS(id))
      dispatch(
        fetchAttendancePartner(filters, paginate.per_page, paginate.page)
      )
    })
  }

  useEffect(() => {
    dispatch(fetchAttendancePartner(filters, paginate.per_page, paginate.page))
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left">OS</StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Parceiro
              </StyledTableCellHeader>
              <StyledTableCellHeader>Status do Pag.</StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Mês Referência
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Items
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Valor
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Ações
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(laundryService => (
              <TableRow key={laundryService?.id} hover>
                {/* <StyledTableCellBody>
                  <IconButton type="button" className={classes.menuItemLi}>
                    <Icon
                      path={mdiChevronDown}
                      size={1}
                      className={classes.menuItemLiIcon}
                    />
                  </IconButton>
                </StyledTableCellBody> */}
                <StyledTableCellBody>
                  {laundryService?.id_laundry_client}
                </StyledTableCellBody>
                <StyledTableCellBody>
                  {laundryService?.laundry?.name}
                </StyledTableCellBody>
                <StyledTableCellBody>
                  <Box
                    className={classes.status}
                    style={{
                      backgroundColor: laundryService?.is_paid
                        ? colors.sucess
                        : colors.error,
                    }}
                  >
                    {laundryService?.is_paid ? 'Pago' : 'Em aberto'}
                  </Box>
                </StyledTableCellBody>
                <StyledTableCellBody>
                  {laundryService?.month}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {laundryService?.items}
                </StyledTableCellBody>

                <StyledTableCellBody align="center">
                  {exportFormatedMoney(laundryService?.total_with_discount)}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  <IconButton
                    ref={el => (anchorRefs.current[laundryService?.id] = el)}
                    key={laundryService?.id}
                    id={laundryService?.id}
                    aria-controls={
                      open?.id
                        ? `menu-list-grow-${laundryService?.id}`
                        : undefined
                    }
                    aria-haspopup="true"
                    onClick={() => {
                      handleToggle(laundryService?.id)
                    }}
                  >
                    <Icon path={mdiDotsVertical} size={1} />
                  </IconButton>

                  <Popper
                    open={open?.id === laundryService?.id}
                    role={undefined}
                    anchorEl={anchorRefs.current[laundryService?.id]}
                    transition
                    disablePortal
                    style={{
                      zIndex: 99999999,
                    }}
                    placement="bottom-end"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement == 'bottom' ? 'left-end' : 'top-end',
                        }}
                      >
                        <Paper className={classes.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open?.id}
                              id={`menu-list-grow-${laundryService.id}`}
                              onKeyDown={handleListKeyDown}
                              className={classes.menuList}
                            >
                              <MenuItem
                                className={classes.ref}
                                style={
                                  placement === 'bottom-end'
                                    ? { top: '-2px' }
                                    : { bottom: '-2px' }
                                }
                              />
                              <MenuItem
                                onClick={handleClose}
                                className={classes.menuItem}
                              >
                                <IconButton
                                  component={Link}
                                  to={`/partners-module/os/${laundryService?.id}`}
                                  className={classes.menuItemLi}
                                >
                                  <Icon
                                    path={mdiEyeOutline}
                                    size={1}
                                    className={classes.menuItemLiIcon}
                                  />
                                  Visualizar
                                </IconButton>
                              </MenuItem>

                              <MenuItem
                                onClick={handleClose}
                                className={classes.menuItem}
                              >
                                <IconButton
                                  component={Link}
                                  to={`/billing?service_id=${laundryService?.id}&type=Receita`}
                                  className={classes.menuItemLi}
                                  disabled={!laundryService?.hasBilling}
                                >
                                  <Icon
                                    path={mdiCash}
                                    size={1}
                                    className={classes.menuItemLiIcon}
                                  />
                                  Pagamentos
                                </IconButton>
                              </MenuItem>

                              {renderRouteRole(['gerente_lavanderia'], role) &&
                                !laundryService?.hasBilling && (
                                  <MenuItem
                                    onClick={handleClose}
                                    className={classes.menuItem}
                                  >
                                    <IconButton
                                      className={classes.menuItemLi}
                                      onClick={() =>
                                        closeOS(laundryService?.id)
                                      }
                                    >
                                      <Icon
                                        path={mdiCalendarLock}
                                        size={1}
                                        className={classes.menuItemLiIcon}
                                      />
                                      Fechar Os
                                    </IconButton>
                                  </MenuItem>
                                )}

                              {renderRouteRole(['gerente_lavanderia'], role) &&
                                laundryService?.hasBilling && (
                                  <MenuItem
                                    onClick={handleClose}
                                    className={classes.menuItem}
                                  >
                                    <IconButton
                                      className={classes.menuItemLi}
                                      onClick={() =>
                                        reopenOs(laundryService?.id)
                                      }
                                    >
                                      <Icon
                                        path={mdiCalendarLock}
                                        size={1}
                                        className={classes.menuItemLiIcon}
                                      />
                                      Reabrir Os
                                    </IconButton>
                                  </MenuItem>
                                )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination
          from={meta?.from}
          to={meta?.to}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={paginate?.per_page}
          rows={meta?.total}
          page={paginate?.page}
          handleChangePage={handleChangePage}
        />
      </TableContainer>
    </>
  )
}

export default List
