/* eslint-disable eqeqeq */
/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { useSelector } from 'react-redux'
import { addDays, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { exportFormatedMoney } from '../../../util/utils'
import Pagination from '../../../components/hooks/BasePagination'

const List = ({ form, setForm }) => {
  const history = useSelector(state => state.productHistory.history)
  const meta = useSelector(state => state.productHistory.meta)

  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    setPaginate({
      ...paginate,
      page: newPage,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>
                Tipo de Movimentação
              </StyledTableCellHeader>
              <StyledTableCellHeader>Produto</StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Quantidade
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Saldo anterior
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Novo saldo
              </StyledTableCellHeader>
              <StyledTableCellHeader>Operador</StyledTableCellHeader>
              <StyledTableCellHeader>Usuário</StyledTableCellHeader>
              <StyledTableCellHeader>
                Data da movimentação
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody>{item?.moviment_type}</StyledTableCellBody>
                <StyledTableCellBody>{item?.product_name}</StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.quantity}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.last_stock}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.actual_stock}
                </StyledTableCellBody>
                <StyledTableCellBody>{item?.technical}</StyledTableCellBody>
                <StyledTableCellBody>{item?.user}</StyledTableCellBody>
                <StyledTableCellBody>{item?.created_at}</StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        from={meta?.from}
        to={meta?.to}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={paginate?.per_page}
        rows={meta?.total}
        page={paginate?.page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export default List
