/* eslint-disable no-shadow */
/* eslint-disable simple-import-sort/sort */
import { Grid, Typography, withStyles } from '@material-ui/core'
import styles from '../../../../resources/theme/global'
import React from 'react'
import colors from '../../../../assets/colors'
import { useSelector } from 'react-redux'
import Filter from './Filter.jsx'
import List from './List.jsx'
import { renderRoute } from '../../../../util/utils.js'
import { Link, Route } from 'react-router-dom'

const Laundries = props => {
  const permissions = useSelector(state => state.auth.permissions)

  const { classes } = props

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            Parceiros
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Filter classes={classes} permissions={permissions} />
        </Grid>
      </Grid>

      {renderRoute(['create-attendances'], permissions) && (
        <Link to="/laundry/edit/:id" />
      )}

      {renderRoute(['create-attendances'], permissions) && (
        <Link to="/laundry/visualization/:id" />
      )}

      {renderRoute(['create-attendances'], permissions) && (
        <Link to="/laundry/new" />
      )}

      <List />
    </>
  )
}

export default withStyles(styles)(Laundries)
