import { Button, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import styles from '../../../../resources/theme/global'

import { renderRoute } from '../../../../util/utils'
import colors from '../../../../assets/colors'
import Filter from './Filter'
import SummaryCard from './SummaryCard/SummaryCard'
import { fetchAttendancePartner } from '../PartnerAttendanceActions'
import List from './List'

// import Billings from './ModalAttachBilling'
// import Filter from './Filter'
// import List from './List'
// import Details from './Details'
// import Card from './Card'
// import ClientAddress from './ClientAddress'

const PartnersOss = props => {
  const { classes } = props

  const permissions = useSelector(state => state.auth.permissions)
  const totals = useSelector(state => state.partnerAttendances.totals)
  const today = useSelector(state => state.partnerAttendances.today)
  const filters = useSelector(state => state.partnerAttendances.filters)

  const dispatch = useDispatch()
  // const confirm = useConfirm()

  // const permissions = useSelector(state => state.auth.permissions)
  // const filters = useSelector(state => state.attendanceLaundry.filters)
  // const totals = useSelector(state => state.attendanceLaundry.totals)
  // const today = useSelector(state => state.attendanceLaundry.today)
  // const pagination = useSelector(state => getAttendanceListData(state))

  // useEffect(() => {
  //   return () => dispatch(clearFilters())
  // }, [])

  // const confirmCloseAll = () => {
  //   confirm({
  //     description: 'Deseja fechar todas as OSs em aberto?',
  //     title: 'Tem certeza?',
  //     confirmationText: 'Sim',
  //     cancellationText: 'Cancelar',
  //     dialogProps: {
  //       fullWidth: true,
  //     },
  //   }).then(async () => {
  //     const resp = await dispatch(closeAllLaundryOSs())
  //     if (resp.status === 200) {
  //       dispatch(
  //         fetchAttendanceLaundry(
  //           filters,
  //           pagination.sizePerPage,
  //           pagination.page
  //         )
  //       )
  //     }
  //   })
  // }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            OSs de Parceiros
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Filter classes={classes} />
        </Grid>

        <List />
      </Grid>

      {/* <Route path="/laundry-os/:id/tickets" component={Billings} /> */}

      {/* 
      <Grid
        container
        spacing={1}
        style={{ marginBottom: '20px' }}
        justify="flex-end"
      >
        {renderRoute(['update-laundry-os'], permissions) && (
          <Grid item>
            <Button
              color="primary"
              type="button"
              onClick={() => confirmCloseAll()}
            >
              Fechar Todas
            </Button>
          </Grid>
        )}
      </Grid> */}
    </>
  )
}

export default withStyles(styles)(PartnersOss)
