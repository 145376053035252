/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Badge,
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { renderRoute, renderRouteRole } from '../../../../util/utils'
import { fetchAttendanceInit } from '../../../attendance/AttendanceActions'
import { Link, Route } from 'react-router-dom'
import {
  mdiAccountSearch,
  mdiFilterMultiple,
  mdiMagnifyExpand,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import BaseFilter from '../../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import SelectField from '../../../components/SelectField'
import { fetchServiceItems, makeReport } from '../ItemsReportActions'
import colors from '../../../../assets/colors'
import BaseFilterModal from '../../../../components/hooks/BaseFilterModal'
import { KeyboardDatePicker } from '@material-ui/pickers'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles({
  container: {
    width: '370px',
  },
  form: {
    maxHeight: 'calc(330px + 50px)',
    overflowY: 'auto',
  },
})

export default function Filter(props) {
  const laundries = useSelector(state => state.laundryItemsReport.laundries)
  const statuses = useSelector(state => state.laundryItemsReport.statuses)

  const meta = useSelector(state => state.laundryItemsReport.meta)
  const filters = useSelector(state => state.laundryItemsReport.filters)
  const permissions = useSelector(state => state.auth.permissions)
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    bar_code: '',
    laundry_id: '',
    date_start: null,
    date_end: null,
    status_id: '',
    is_rework: '',
    is_delivery: '',
    ...filters,
  })

  const filterClasses = filterStylesComponents()

  const [open, setOpen] = useState(false)

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchLaundries(form))
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSearchByName = e => {
    handleSubmit()
  }

  const handleSubmit = () => {
    const data = {
      ...form,
      page: meta?.current_page,
      per_page: meta?.per_page,
    }

    dispatch(fetchServiceItems(data))
  }

  useEffect(() => {
    handleSubmit()
  }, [form?.laundry_id])

  return (
    <>
      <BaseFilter>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item sm={4} xs={12}>
            <Input
              fullWidth
              style={{ marginTop: '16px' }}
              name="bar_code"
              value={form?.bar_code}
              onChange={handleChange}
              onBlur={e => {
                handleSubmit()
              }}
              id="standard-adornment-person"
              placeholder="Pesquise por Etiqueta"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search bar_code"
                    onClick={handleSearchByName}
                  >
                    <Icon path={mdiMagnifyExpand} size={1} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <SelectField
              style={{ color: 'black', marginTop: '0px' }}
              options={laundries}
              setForm={(e, value) => {
                setForm({
                  ...form,
                  laundry: value,
                  laundry_id: value?.id,
                })
              }}
              data={form?.laundry}
              name="laundry"
              label="Filtre por Lavanderia"
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item sm={4} xs={12} className={filterClasses.areaButtons}>
            <IconButton
              className={filterClasses.btnMoreFilters}
              onClick={handleOpen}
              color={colors.textPrimary}
            >
              <Icon
                path={mdiFilterMultiple}
                size={1}
                color={colors.secondary}
              />
              Outros Filtros
            </IconButton>

            {renderRoute(['create-attendances'], permissions) && (
              <IconButton
                className={filterClasses.btnAdd}
                onClick={() => {
                  dispatch(makeReport({ ...filters, download: true }))
                }}
              >
                <Icon path={mdiMicrosoftExcel} size={1} />
                Download
              </IconButton>
            )}
          </Grid>
        </Grid>

        <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
          <Grid item xs={12} style={{ width: '450px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: '16px', fontWeight: 'normal' }}
                  color={colors.primary}
                >
                  Utilize os campos abaixo para encontrar <br /> um item de
                  atendimento
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    handleSubmit()
                    handleOpen()
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Input
                        fullWidth
                        style={{ marginTop: '16px' }}
                        name="bar_code"
                        value={form?.bar_code}
                        onChange={handleChange}
                        onBlur={e => {
                          handleSubmit()
                        }}
                        id="standard-adornment-person"
                        placeholder="Pesquise por Etiqueta"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="search bar_code"
                              onClick={handleSearchByName}
                            >
                              <Icon path={mdiMagnifyExpand} size={1} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <SelectField
                        style={{ color: 'black', marginTop: '0px' }}
                        options={laundries}
                        setForm={(e, value) => {
                          setForm({
                            ...form,
                            laundry: value,
                            laundry_id: value?.id,
                          })
                        }}
                        data={form?.laundry}
                        name="laundry"
                        label="Filtre por Lavanderia"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12} lg={6}>
                      <KeyboardDatePicker
                        clearable
                        value={form.date_start}
                        label="Data da coleta (de)"
                        fullWidth
                        clearLabel="Limpar"
                        invalidDateMessage="Data inválida"
                        autoOk
                        inputVariant="standard"
                        onChange={date =>
                          setForm({
                            ...form,
                            date_start: date,
                          })
                        }
                        format="dd/MM/yyyy"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12} lg={6}>
                      <KeyboardDatePicker
                        clearable
                        value={form.date_end}
                        label="Data da coleta (até)"
                        fullWidth
                        clearLabel="Limpar"
                        invalidDateMessage="Data inválida"
                        autoOk
                        inputVariant="standard"
                        onChange={date =>
                          setForm({
                            ...form,
                            date_end: date,
                          })
                        }
                        format="dd/MM/yyyy"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SelectField
                        fullWidth
                        options={statuses}
                        setForm={(e, value) => {
                          setForm({
                            ...form,
                            status: value,
                            status_id: value?.id,
                          })
                        }}
                        data={form.status || null}
                        name="status"
                        label="Status"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        name="is_rework"
                        value={form?.is_rework}
                        onChange={handleChange}
                        fullWidth
                        label="Retrabalho"
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value="0">Não</MenuItem>
                        <MenuItem value="1">Sim</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        name="is_delivery"
                        value={form?.is_delivery}
                        onChange={handleChange}
                        fullWidth
                        label="Delivery"
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value="0">Não</MenuItem>
                        <MenuItem value="1">Sim</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{ margin: '1em' }}
                      >
                        <Grid item>
                          <Button
                            type="submit"
                            color={colors.primary}
                            variant="contained"
                            className={filterClasses.btnAdd}
                          >
                            <SearchIcon />
                            {` Pesquisar`}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
                {/* <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                <Grid
                  container
                  className={classes.gridContainer}
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sm={6} xs={12} lg={6}>
                    <TextField
                      onChange={handleChange}
                      name="id"
                      label="Número da OS"
                      margin="dense"
                      className={formClasses.baseInput}
                      variant="standard"
                      fullWidth
                      value={form.id}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6}>
                    <TextField
                      onChange={handleChange}
                      name="name"
                      label="Nome"
                      margin="dense"
                      className={formClasses.baseInput}
                      variant="standard"
                      fullWidth
                      value={form.name}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6}>
                    <InputMask
                      mask={
                        form.phone?.replace(/[^\d]/g, '').length <= 10
                          ? '(99) 9999-9999?'
                          : '(99) 99999-9999'
                      }
                      formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                      maskChar=""
                      value={form.phone}
                      onChange={e => {
                        setForm({
                          ...form,
                          phone: e.target.value.replace(/[^\d]/g, ''),
                        })
                      }}
                    >
                      {inputProps => (
                        <TextValidator
                          {...inputProps}
                          label="Telefone"
                          fullWidth
                          variant="standard"
                          validators={[
                            'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                          ]}
                          errorMessages={['Telefone inválido']}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6}>
                    <SelectField
                      options={statuses}
                      setForm={updateField}
                      data={form.status || null}
                      name="status"
                      variant="standard"
                      label="Status"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SelectField
                      options={attendants}
                      setForm={updateField}
                      data={form.attendant || null}
                      name="attendant"
                      variant="standard"
                      label="Atendente"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6}>
                    <KeyboardDatePicker
                      clearable
                      value={form.date_start}
                      label="Data Inicial"
                      fullWidth
                      clearLabel="Limpar"
                      invalidDateMessage="Data inválida"
                      autoOk
                      inputVariant="standard"
                      onChange={date =>
                        setForm({
                          ...form,
                          date_start: date,
                        })
                      }
                      format="dd/MM/yyyy"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6}>
                    <KeyboardDatePicker
                      clearable
                      value={form.date_end}
                      label="Data Final"
                      fullWidth
                      clearLabel="Limpar"
                      invalidDateMessage="Data inválida"
                      autoOk
                      inputVariant="standard"
                      onChange={date =>
                        setForm({
                          ...form,
                          date_end: date,
                        })
                      }
                      format="dd/MM/yyyy"
                    />
                  </Grid>

                  {renderRouteRole(['administrador'], role) && (
                    <Grid item sm={6} xs={12} lg={6}>
                      <SelectField
                        options={companies}
                        setForm={updateField}
                        data={form.company || null}
                        name="company"
                        variant="standard"
                        label="Empresa"
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      type="submit"
                      color={colors.primary}
                      variant="contained"
                      className={filterClasses.btnSalvar}
                      onClick={handleOpen}
                    >
                      <SearchIcon />
                      {` Pesquisar`}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm> */}
              </Grid>
            </Grid>
          </Grid>
        </BaseFilterModal>
      </BaseFilter>
    </>
  )
}
