import React, { useEffect, useRef, useState } from 'react'
import BaseCreatePage from '../../../../../components/hooks/BaseCreatePage'
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import colors from '../../../../../assets/colors'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useParams } from 'react-router-dom'
import {
  deleteItem,
  edit,
  updatePersonalized,
  updateRework,
  updateServiceItem,
} from '../../PartnerAttendanceActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../../components/hooks/BaseTableComponents'
import {
  exportFormatedMoney,
  renderRoute,
  renderRouteRole,
} from '../../../../../util/utils'
import Stat from './HeaderStat'
import ClientAddress from './ClientAddress'
import NumberFormatCustom from '../../../../components/NumberFormat'
import { mdiDelete, mdiDotsVertical, mdiNoteEditOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Link, Route } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import EditItem from './Edititem'
import Filter from './Filter'
import Pagination from '../../../../components/Pagination'

const useStyles = makeStyles({
  rowMessage: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'flex-end',
    gap: 4,
    height: 'auto',
    fontWeight: 'normal',
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
})

const OsDetails = () => {
  const details = useSelector(
    state => state.partnerAttendances.showingAttendance
  )
  const showingAttendanceFilter = useSelector(
    state => state.partnerAttendances.showingAttendanceFilter
  )

  const permissions = useSelector(state => state.auth.permissions)

  const classes = useStyles()
  const filterStyles = filterStylesComponents()
  const { id } = useParams()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const role = useSelector(state => state.auth.role)

  const [open, setOpen] = useState({
    id: '',
  })

  const [clientAddress, setClientAddress] = useState({
    open: false,
    itemId: null,
    is_delivery: false,
  })

  const [editingFinalValue, setEditingFinalValue] = useState({
    id: null,
    value: null,
  })

  const handleSetToRework = async (itemId, isRework) => {
    const data = { is_rework: isRework }
    const resp = await dispatch(updateRework(itemId, data))
    dispatch(edit(id, showingAttendanceFilter))
  }

  const handleToggleClientAddress = (itemId, is_delivery) => {
    setClientAddress({
      open: !clientAddress?.open,
      itemId: clientAddress?.itemId ? null : itemId,
      is_delivery: is_delivery,
    })
  }

  const handleSetIsPersonalized = async (
    itemId,
    isPersonalized,
    default_value = null
  ) => {
    const data = {
      is_personalized: isPersonalized,
      default_value: default_value,
    }
    const resp = await dispatch(updatePersonalized(itemId, data))
    dispatch(edit(id, showingAttendanceFilter))
  }

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const confirmDelete = async itemId => {
    confirm({
      description: 'Deseja excluir este item?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(deleteItem(itemId))
      if (resp.status === 200) {
        await dispatch(edit(id, showingAttendanceFilter))
      }
    })
  }

  useEffect(() => {
    dispatch(edit(id))
  }, [id])

  return (
    <BaseCreatePage
      page="OS de Lavanderia"
      path="Detalhes"
      // onClose={onClose}
      pageLink="/partners-module/oss"
    >
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <Stat label="Mês de Referência" value={details?.month} />
          <Stat label="Parceiro" value={details?.laundry?.name} />
          <Stat label="Itens" value={details?.items?.length} />
          <Stat label="Valor" value={details?.total_with_discount} isMonetary />
          <Stat
            label="Total em Restaurações:"
            value={details?.total_restoration}
            isMonetary
          />
        </Box>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '2em' }}>
        <Filter />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          marginTop: '2em',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer
              style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCellHeader>ID</StyledTableCellHeader>
                    <StyledTableCellHeader>Serviço</StyledTableCellHeader>
                    <StyledTableCellHeader>Etiqueta</StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Medida
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Qtd.
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Valor Unit.
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Valor Final
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Data de Coleta
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Entregue em
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Status
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="left">
                      Observações
                    </StyledTableCellHeader>

                    <StyledTableCellHeader align="center">
                      Retrabalho
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Delivery
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Preço Customizado
                    </StyledTableCellHeader>
                    {renderRouteRole(['gerente_lavanderia'], role) && (
                      <StyledTableCellHeader align="center">
                        Ações
                      </StyledTableCellHeader>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details?.items?.map((item, index) => (
                    <TableRow key={index} hover>
                      <StyledTableCellBody>{item?.id}</StyledTableCellBody>
                      <StyledTableCellBody>
                        {item?.serviceType?.name}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {item?.bar_code}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item?.measure_type}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item?.quantity}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item?.is_personalized ? (
                          <TextField
                            placeholder="Valor"
                            value={
                              editingFinalValue?.id == item?.id
                                ? editingFinalValue?.value
                                : item?.default_value
                            }
                            onBlur={e => {
                              handleSetIsPersonalized(
                                item?.id,
                                item?.is_personalized,
                                editingFinalValue?.value
                              )
                            }}
                            onClick={() => {
                              setEditingFinalValue({
                                ...editingFinalValue,
                                id: item?.id,
                                value: item?.default_value,
                              })
                            }}
                            onChange={e => {
                              setEditingFinalValue({
                                ...editingFinalValue,
                                value: e.target.value,
                              })
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: {
                                prefix: 'R$ ',
                                style: {
                                  textAlign: 'center',
                                  maxWidth: '100px',
                                },
                              },
                            }}
                          />
                        ) : (
                          <>{exportFormatedMoney(item?.default_value)}</>
                        )}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {exportFormatedMoney(item?.final_value)}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item?.created_at}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item?.delivered_at}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item?.status?.name}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {item?.options?.description || '-'}
                      </StyledTableCellBody>

                      <StyledTableCellBody align="center">
                        {renderRouteRole(['gerente_lavanderia'], role) ? (
                          <Switch
                            checked={Boolean(item?.is_rework)}
                            onChange={e =>
                              handleSetToRework(item?.id, !item?.is_rework)
                            }
                          />
                        ) : (
                          <>{item?.is_rework ? 'Sim' : 'Não'}</>
                        )}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {renderRouteRole(['gerente_lavanderia'], role) ? (
                          <Switch
                            checked={Boolean(item?.is_delivery)}
                            onChange={e => {
                              handleToggleClientAddress(
                                item?.id,
                                !item?.is_delivery
                              )
                            }}
                          />
                        ) : (
                          <>{item?.is_delivery ? 'Sim' : 'Não'}</>
                        )}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {renderRouteRole(['gerente_lavanderia'], role) ? (
                          <Switch
                            checked={Boolean(item?.is_personalized)}
                            onChange={e => {
                              handleSetIsPersonalized(
                                item?.id,
                                !item?.is_personalized,
                                item?.default_value
                              )
                            }}
                          />
                        ) : (
                          <>{item?.is_personalized ? 'Sim' : 'Não'}</>
                        )}
                      </StyledTableCellBody>
                      {renderRouteRole(['gerente_lavanderia'], role) && (
                        <StyledTableCellBody align="center">
                          <IconButton
                            ref={el => (anchorRefs.current[item?.id] = el)}
                            key={item.id}
                            id={item?.id}
                            aria-controls={
                              open?.id ? `menu-list-grow-${item.id}` : undefined
                            }
                            aria-haspopup="true"
                            onClick={() => {
                              handleToggle(item.id)
                            }}
                          >
                            <Icon path={mdiDotsVertical} size={1} />
                          </IconButton>

                          <Popper
                            open={open?.id === item?.id}
                            role={undefined}
                            anchorEl={anchorRefs.current[item?.id]}
                            transition
                            disablePortal
                            style={{
                              zIndex: 99999999,
                            }}
                            placement="bottom-end"
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement == 'bottom'
                                      ? 'left-end'
                                      : 'top-end',
                                }}
                              >
                                <Paper className={classes.paper}>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open?.id}
                                      id={`menu-list-grow-${item.id}`}
                                      onKeyDown={handleListKeyDown}
                                      className={classes.menuList}
                                    >
                                      <MenuItem
                                        onClick={handleClose}
                                        className={classes.menuItem}
                                      >
                                        <IconButton
                                          component={Link}
                                          to={`/partners-module/os/${id}/service-item/${item?.id}`}
                                          aria-label="edit"
                                          className={classes.menuItemLi}
                                        >
                                          <Icon
                                            path={mdiNoteEditOutline}
                                            size={1}
                                            className={classes.menuItemLiIcon}
                                          />
                                          Editar
                                        </IconButton>
                                      </MenuItem>

                                      {[40].includes(item?.status?.id) && (
                                        <MenuItem
                                          onClick={handleClose}
                                          className={classes.menuItem}
                                        >
                                          <IconButton
                                            aria-label="edit"
                                            className={classes.menuItemLi}
                                            onClick={() =>
                                              confirmDelete(item?.id)
                                            }
                                          >
                                            <Icon
                                              path={mdiDelete}
                                              size={1}
                                              className={classes.menuItemLiIcon}
                                            />
                                            Deletar
                                          </IconButton>
                                        </MenuItem>
                                      )}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </StyledTableCellBody>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      <ClientAddress
        open={clientAddress?.open}
        itemId={clientAddress?.itemId}
        setOpen={handleToggleClientAddress}
        isDelivery={clientAddress?.is_delivery}
      />

      {renderRoute(['create-attendances'], permissions) && (
        <Route
          path="/partners-module/os/:id/service-item/:serviceItem"
          component={EditItem}
        />
      )}
    </BaseCreatePage>
  )
}

export default OsDetails
