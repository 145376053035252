import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../../../resources/theme/users'
import { edit, fetchLaundriesInit, update } from '../../PartnersActions'
import Form from './Form'
import BaseCreatePage from '../../../../../components/hooks/BaseCreatePage'
import { Grid, makeStyles } from '@material-ui/core'
import colors from '../../../../../assets/colors'

const useStyles = makeStyles({
  rowMessage: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  message: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 'auto',
    fontWeight: 'normal',
  },
  btnCalendar: {
    color: '#FFFF',
    fontSize: '14px',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    padding: '4px 12px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: colors.primary,
    },
  },
})

function EditLaundryPage(props) {
  const { history } = props
  const classes = useStyles()

  const dispatch = useDispatch()
  const paymentMethods = useSelector(state => state.laundries.paymentMethods)

  const fetchEditingLaundry = () => {
    dispatch(edit(props.match.params.id))
  }

  useEffect(() => {
    fetchEditingLaundry()
  }, [])

  useEffect(() => {
    if (paymentMethods?.length) return

    dispatch(fetchLaundriesInit())
  }, [paymentMethods])

  return (
    <BaseCreatePage
      page="Parceiros"
      path="Editar"
      pageLink="/partners-module/partners"
    >
      <Grid
        container
        xs={12}
        alignContent="center"
        justifyContent="flex-start"
        className={classes.rowMessage}
      >
        <Grid item className={classes.message}>
          Utilize os campos abaixo para editar a lavanderia
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: '1em' }}>
        <Form
          edit
          mutate={fetchEditingLaundry}
          editingId={props.match.params.id}
        />
      </Grid>
    </BaseCreatePage>
  )
}

export default withStyles(styles)(EditLaundryPage)
