import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { exportFormatedMoney } from '../../../../../util/utils'
import colors from '../../../../../assets/colors'

const useStyles = makeStyles({
  statbox: {
    padding: '4px',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '8px',
    gap: '12px',
    color: colors.primary,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

const Stat = ({ label, value, isMonetary = false }) => {
  const classes = useStyles()

  return (
    <Box className={classes.statbox}>
      <span>{label}</span>
      <span style={{ color: colors.grayMedium }}>
        {isMonetary ? exportFormatedMoney(value) : value}
      </span>
    </Box>
  )
}

export default Stat
