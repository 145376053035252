/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import { clearBilling, edit, fetchBilling, update } from '../BillingActions'
import Form from './Form'
import { Link } from 'react-router-dom'
import { Grid, Slide } from '@material-ui/core'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import colors from '../../../assets/colors'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

function EditBill(props) {
  const { classes, location } = props
  const dispatch = useDispatch()
  const [typeGeneric, setTypeGeneric] = useState('')
  const filters = useSelector(state => state.billing.filters)

  useEffect(() => {
    dispatch(clearBilling())
    dispatch(edit(props.match.params.id))
  }, [])

  const billing = useSelector(state => state.billing.editingBilling)

  const onSubmit = values => {
    dispatch(update(values, typeGeneric)).then(data => {
      if (data?.status === 200) {
        dispatch(fetchBilling(filters))
        props.history.goBack()
      }
    })
  }

  const onClose = () => {
    props.history.goBack()
  }

  const getPath = () => {
    if (billing?.service?.client)
      return `/attendance/${billing?.service.id}/edit`
    if (billing?.service?.laundry)
      return `/laundry-os/${billing?.service.id}/details`

    return null
  }

  useEffect(() => {
    const { state } = location

    if (state?.typeGeneric) {
      setTypeGeneric(state?.typeGeneric)
    }
  }, [])

  const returnInfo = () => {
    if (getPath()) {
      return (
        <Typography
          variant="inherit"
          color="inherit"
          component={Link}
          to={getPath()}
        >
          {billing?.service.id}
        </Typography>
      )
    }
  }

  return (
    <BaseFilterModal title="Editar conta" open handleOpen={onClose}>
      <Grid container xs={12} item direction="row" style={{ width: '540px' }}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos abaixo para editar uma conta
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form data={billing} onClose={onClose} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default withStyles(styles)(EditBill)
