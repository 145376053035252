/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../../components/hooks/BaseTableComponents'
import {
  mdiAlert,
  mdiClipboardTextClockOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiNoteEdit,
  mdiNoteEditOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../../assets/colors'
import Pagination from '../../../../../components/hooks/BasePagination'

const List = ({ form, setForm }) => {
  const items = useSelector(state => state.leadsReport.items)
  const meta = useSelector(state => state.leadsReport.meta)

  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    setPaginate({
      ...paginate,
      page: newPage,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left" style={{ width: '20%' }}>
                Atendente
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">Nº OS</StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Nome do cliente
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">Status</StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Vendido
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(lead => (
              <TableRow key={lead?.id} hover>
                <StyledTableCellBody align="left">
                  {lead?.attendant}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {lead?.os}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {lead?.client_name}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {lead?.state_name}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {lead?.sold}
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination
          from={meta?.from}
          to={meta?.to}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={paginate?.per_page}
          rows={meta?.total}
          page={paginate?.page}
          handleChangePage={handleChangePage}
        />
      </TableContainer>
    </>
  )
}

export default List
