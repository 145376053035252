/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilter from '../../../../../components/hooks/BaseFilter'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiFormatTextRotationDownVertical,
  mdiMagnifyExpand,
  mdiMenuLeft,
  mdiMenuRight,
  mdiMicrosoftExcel,
} from '@mdi/js'
import SearchIcon from '@material-ui/icons/Search'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { renderRoute } from '../../../../../util/utils'
import colors from '../../../../../assets/colors'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import {
  edit,
  exportOSsRelatory,
  fetchAttendancePartner,
} from '../../PartnerAttendanceActions'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { CheckBox } from '@material-ui/icons'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
  filterBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
  },
  periodFilter: {
    color: colors.primary,
    display: 'flex',
    minWidth: '210px',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '14px',
  },
  periodFilterBtn: {
    padding: 0,
    transition: 'all .4s',
    color: colors.primary,
    '&:hover': {
      opacity: '.7',
      backgroundColor: '#FFF',
    },
  },
})

export default function Filter({}) {
  const permissions = useSelector(state => state.auth.permissions)
  // const items = useSelector(state => state.partnerAttendances.items)
  // const meta = useSelector(state => state.partnerAttendances.meta)
  // const filters = useSelector(state => state.partnerAttendances.filters)
  const showingAttendanceFilter = useSelector(
    state => state.partnerAttendances.showingAttendanceFilter
  )

  const dispatch = useDispatch()

  const { id } = useParams()

  const [open, setOpen] = useState(false)

  const [form, setForm] = useState({
    bar_code: '',
    date_start: null,
    date_end: null,
    is_rework: false,
    is_personalized: false,
    is_delivery: false,
  })

  const filterClasses = filterStylesComponents()

  const styles = useStyles()

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSearchBarCode = () => {
    dispatch(edit(id, form))
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(edit(id, form))
    handleOpen()
  }

  const handleExportOssRelatory = () => {
    dispatch(exportOSsRelatory(null, `laundryService/os-report/items/${id}`))
  }

  return (
    <BaseFilter>
      <Grid
        item
        container
        xs={6}
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* <Grid item sm={6} xs={12}>
          <Input
            fullWidth
            name="bar_code"
            value={form?.bar_code}
            onChange={handleChange}
            id="standard-adornment-person"
            placeholder="Pesquise por Etiqueta:"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search name"
                  onClick={handleSearchBarCode}
                >
                  <Icon path={mdiMagnifyExpand} size={1} />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid> */}
      </Grid>

      <Grid
        item
        container
        xs={6}
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item xs={12} className={filterClasses.areaButtons}>
          <Button
            onClick={handleOpen}
            color={colors.textPrimary}
            className={filterClasses.btnMoreFilters}
          >
            <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
            Filtros
          </Button>

          <IconButton
            className={filterClasses.btnAdd}
            onClick={handleExportOssRelatory}
          >
            <Icon path={mdiMicrosoftExcel} size={1} />
            Download
          </IconButton>
        </Grid>
      </Grid>

      <BaseFilterModal
        open={open}
        hiddenHelpButton
        handleOpen={handleOpen}
        title="FILTRO"
      >
        <Grid item xs={12} style={{ width: '500px' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Input
                  fullWidth
                  style={{ marginTop: '16px' }}
                  name="bar_code"
                  value={form?.bar_code}
                  onChange={handleChange}
                  id="standard-adornment-person"
                  placeholder="Pesquise por Etiqueta:"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search name"
                        onClick={handleSubmit}
                      >
                        <Icon path={mdiMagnifyExpand} size={1} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  clearable
                  value={form.date_start}
                  label="Data Inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  inputVariant="standard"
                  onChange={date =>
                    setForm({
                      ...form,
                      date_start: date,
                    })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  clearable
                  value={form.date_end}
                  label="Data Final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  inputVariant="standard"
                  onChange={date =>
                    setForm({
                      ...form,
                      date_end: date,
                    })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Box
                  style={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'flex-start',

                    color: colors.textGray,
                  }}
                >
                  <Switch
                    checked={Boolean(form?.is_rework)}
                    onChange={e =>
                      setForm({ ...form, is_rework: !form.is_rework })
                    }
                  />
                  <span>Retrabalho</span>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  style={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'center',

                    color: colors.textGray,
                  }}
                >
                  <Switch
                    checked={Boolean(form?.is_delivery)}
                    onChange={e =>
                      setForm({ ...form, is_delivery: !form?.is_delivery })
                    }
                  />
                  <span>Delivery</span>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  style={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: colors.textGray,
                  }}
                >
                  <Switch
                    checked={Boolean(form?.is_personalized)}
                    onChange={e =>
                      setForm({
                        ...form,
                        is_personalized: !form.is_personalized,
                      })
                    }
                  />
                  <span>Customizado</span>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Button className={filterClasses.submitButton} type="submit">
                    <SearchIcon />
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </BaseFilterModal>
    </BaseFilter>
  )
}
