import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MuiTextField,
  DialogTitle,
  Slide,
  Grid,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import InputMask from 'react-input-mask'
import { TextField } from 'formik-material-ui'
import { useParams } from 'react-router-dom'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import {
  edit,
  serviceItemAddress,
  updateDelivery,
  updateServiceItem,
} from '../../PartnerAttendanceActions'
import axios from 'axios'

const ClientAddress = ({ open, setOpen, itemId, isDelivery }) => {
  const { id } = useParams()

  const [data, setData] = useState({
    name: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
  })

  const dispatch = useDispatch()

  const filterStyles = filterStylesComponents()

  const schema = yup.object().shape({
    cep: yup.string().required('Obrigatório'),
    street: yup.string().required('Obrigatório'),
    number: yup.string().required('Obrigatório'),
    neighborhood: yup.string().required('Obrigatório'),
    city: yup.string().required('Obrigatório'),
    uf: yup.string().required('Obrigatório'),
  })

  const disabled = useMemo(() => data?.id, [data])

  const fetchClientAddress = async () => {
    const resp = await dispatch(serviceItemAddress(itemId))

    if (!resp?.data?.id) return

    setData({
      ...resp?.data,
    })
  }

  useEffect(() => {
    if (!itemId) return
    fetchClientAddress()
  }, [itemId])

  return (
    <BaseFilterModal
      open={open}
      title={'Endereço do cliente'}
      handleOpen={setOpen}
      hiddenHelpButton
    >
      <Grid item xs={12}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            const data = { ...values, is_delivery: isDelivery }
            const resp = await dispatch(updateDelivery(itemId, data))
            await dispatch(edit(id))
            setData({})
            setOpen()
            resetForm({})
          }}
        >
          {({
            submitForm,
            isSubmitting,
            touched,
            errors,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="name"
                    label="Nome do Cliente"
                    fullWidth
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs>
                  <Field name="cep">
                    {({ field }) => (
                      <InputMask
                        {...field}
                        mask="99.999-999"
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        maskChar=""
                        onChange={handleChange}
                        disabled={disabled}
                        onBlur={e => {
                          const findCep = async () => {
                            const currentCep = e.currentTarget?.value.replace(
                              /\D/g,
                              ''
                            )

                            try {
                              if (currentCep?.length === 8) {
                                const resp = await axios
                                  .get(
                                    `https://viacep.com.br/ws/${currentCep}/json/`
                                  )
                                  .then(response => response?.data)

                                setFieldValue('street', resp.logradouro)
                                setFieldValue('neighborhood', resp.bairro)
                                setFieldValue('city', resp.localidade)
                                setFieldValue('uf', resp.uf)
                              }
                            } catch (e) {
                              // console.log(e)
                            }
                          }

                          if (disabled) return

                          findCep()
                        }}
                      >
                        {inputProps => (
                          <MuiTextField
                            {...inputProps}
                            label="CEP"
                            fullWidth
                            error={touched.cep && !!errors.cep}
                            helperText={touched.cep && errors.cep}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="street"
                    label="RUA"
                    fullWidth
                    error={touched.street && !!errors.street}
                    helperText={touched.street && errors.street}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="number"
                    label="Numero"
                    fullWidth
                    error={touched.number && !!errors.number}
                    helperText={touched.number && errors.number}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="neighborhood"
                    label="Bairro"
                    fullWidth
                    error={touched.neighborhood && !!errors.neighborhood}
                    helperText={touched.neighborhood && errors.neighborhood}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="city"
                    label="Cidade"
                    fullWidth
                    error={touched.city && !!errors.city}
                    helperText={touched.city && errors.city}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="uf"
                    label="UF"
                    fullWidth
                    error={touched.uf && !!errors.uf}
                    helperText={touched.uf && errors.uf}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="complement"
                    label="Complemento"
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{ marginTop: '1em' }}
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      onClick={setOpen}
                      color="primary"
                      className={filterStyles.btnMoreFilters}
                    >
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      onClick={submitForm}
                      color="primary"
                      className={filterStyles.btnSalvar}
                    >
                      {data?.id ? 'Apagar' : 'Cadastrar'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </BaseFilterModal>
  )
}

export default React.memo(ClientAddress)
