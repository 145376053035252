import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import colors from '../../../../assets/colors'

import styles from '../../../../resources/theme/global'
import Filter from './Filter'
import List from './List'

const ItemsReport = props => {
  const { classes } = props

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Relátorio de Itens
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Filter />
      </Grid>

      <Grid item xs={12}>
        <List classes={classes} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ItemsReport)
