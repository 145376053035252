/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { useDispatch, useSelector } from 'react-redux'
import Carousel from 'react-material-ui-carousel'

import { getItemHistory } from '../AttendanceActions'
import { dateFormatedFullDate } from '../../../util/utils'
import colors from '../../../assets/colors'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'

import { useParams } from 'react-router-dom'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import { mdiClipboardTextClock, mdiPanoramaVariant } from '@mdi/js'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'

const useStyles = makeStyles(theme => ({
  pageBar: {
    padding: '16px 36px',
    display: 'flex',
    backgroundColor: colors.primary,
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
  },
  centerImage: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    height: 'auto',
    maxWidth: '100%',
    cursor: 'pointer',
  },
}))

function ItemHistoric(props) {
  const classes = useStyles()
  const { itemStatus, documents } = useSelector(
    state => state.attendance.itemHistoric
  )

  const { id } = useParams()

  const [value, setValue] = useState(0)

  const modalRef = useRef()
  const modalImgRef = useRef()
  const captionTextRef = useRef()

  const clickImg = e => {
    modalRef.current.style.display = 'block'
    modalImgRef.current.src = e.target.src
    captionTextRef.current.innerHTML = e.target.alt
  }

  const closesModal = () => {
    modalRef.current.style.display = 'none'
  }

  const dispatch = useDispatch()

  useEffect(() => {
    id && dispatch(getItemHistory(id))
  }, [id])

  return (
    <BaseCreatePage page="Item de serviço" path="Histórico" goBack hiddenReturn>
      <Grid container spacing={2} style={{ marginTop: '1em' }}>
        <Grid item xs={12}>
          <Tabs>
            <Tab
              tabIndex={0}
              value={value}
              setValue={setValue}
              icon={mdiClipboardTextClock}
              label="Histórico"
              removeNavigation={false}
            />
            <Tab
              tabIndex={1}
              value={value}
              setValue={setValue}
              icon={mdiPanoramaVariant}
              label="Fotos"
              removeNavigation={false}
            />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {value == 0 && (
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellHeader>ID</StyledTableCellHeader>
                    <StyledTableCellHeader>Usuário</StyledTableCellHeader>
                    <StyledTableCellHeader>Status</StyledTableCellHeader>
                    <StyledTableCellHeader>Data</StyledTableCellHeader>
                    <StyledTableCellHeader>
                      O que foi alterado
                    </StyledTableCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemStatus?.map(item => (
                    <TableRow key={item.id} hover>
                      <StyledTableCellBody>{item.id}</StyledTableCellBody>
                      <StyledTableCellBody>
                        {item.user.name}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {item.status.name}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {dateFormatedFullDate(item.created_at)}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {item.whatChanged}
                      </StyledTableCellBody>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {value == 1 && documents?.length > 0 && (
            <Carousel autoPlay={false} navButtonsAlwaysVisible>
              {documents?.map((item, key) => (
                <img
                  src={item.link}
                  alt={`Foto ${key + 1}`}
                  className={classes.centerImage}
                  onClick={clickImg}
                />
              ))}
            </Carousel>
          )}
        </Grid>

        <div id="myModal" ref={modalRef} className="modal">
          <span className="close" onClick={closesModal}>
            &times;
          </span>

          <img className="modal-content" ref={modalImgRef} id="img01" />

          <div id="caption" ref={captionTextRef} />
        </div>
      </Grid>
    </BaseCreatePage>
  )
}

export default React.memo(ItemHistoric)
