/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilter from '../../../../components/hooks/BaseFilter'
import {
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Icon from '@mdi/react'
import {
  mdiMagnifyExpand,
  mdiMenuLeft,
  mdiMenuRight,
  mdiMicrosoftExcel,
} from '@mdi/js'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import { renderRoute, renderRouteRole } from '../../../../util/utils'
import colors from '../../../../assets/colors'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import {
  closeAllLaundryOSs,
  exportOSsRelatory,
  fetchAttendancePartner,
} from '../PartnerAttendanceActions'
import { useConfirm } from 'material-ui-confirm'

const useStyles = makeStyles({
  filterBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
  },
  periodFilter: {
    color: colors.primary,
    display: 'flex',
    minWidth: '210px',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '14px',
  },
  periodFilterBtn: {
    padding: 0,
    transition: 'all .4s',
    color: colors.primary,
    '&:hover': {
      opacity: '.7',
      backgroundColor: '#FFF',
    },
  },
})

export default function Filter({}) {
  const permissions = useSelector(state => state.auth.permissions)
  const role = useSelector(state => state.auth.role)
  const items = useSelector(state => state.partnerAttendances.items)
  const meta = useSelector(state => state.partnerAttendances.meta)
  const filters = useSelector(state => state.partnerAttendances.filters)

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const [form, setForm] = useState({
    date: new Date(),
    partner: '',
    ...filters,
  })

  const filterClasses = filterStylesComponents()

  const styles = useStyles()

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleChangeDate = (prev = false) => {
    if (prev) {
      return setForm({
        ...form,
        date: new Date(form?.date?.setMonth(form?.date?.getMonth() - 1)),
      })
    }

    return setForm({
      ...form,
      date: new Date(form?.date?.setMonth(form?.date?.getMonth() + 1)),
    })
  }

  const handleSubmit = () => {
    dispatch(fetchAttendancePartner(form, meta?.per_page, meta?.current_page))
  }

  const handleCloseAllOs = () => {
    confirm({
      description: 'Deseja realmente fechar todas as OSs?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(closeAllLaundryOSs())
      dispatch(
        fetchAttendancePartner(filters, meta?.per_page, meta?.current_page)
      )
    })
  }

  useEffect(() => {
    handleSubmit()
  }, [form?.date])

  return (
    <BaseFilter>
      <Grid
        item
        container
        xs={6}
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item sm={6} xs={12}>
          <Input
            fullWidth
            style={{ marginTop: '16px' }}
            name="partner"
            value={form?.partner}
            onChange={handleChange}
            id="standard-adornment-person"
            placeholder="Pesquise por Parceiro"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="search name" onClick={handleSubmit}>
                  <Icon path={mdiMagnifyExpand} size={1} />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={6}
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item sm={4} xs={12} className={filterClasses.areaButtons}>
          <Box className={styles.periodFilter}>
            <IconButton
              onClick={() => handleChangeDate(true)}
              className={styles.periodFilterBtn}
            >
              <Icon path={mdiMenuLeft} size={0.7} />
            </IconButton>

            <Typography>
              {format(form?.date, "MMMM 'de' yyyy", { locale: ptBR })}
            </Typography>

            <IconButton
              onClick={() => handleChangeDate()}
              className={styles.periodFilterBtn}
            >
              <Icon path={mdiMenuRight} size={0.7} />
            </IconButton>
          </Box>

          {renderRouteRole(['gerente_lavanderia'], role) && (
            <IconButton
              className={filterClasses.btnAdd}
              onClick={() => {
                // dispatch(makeReport(filters))
                dispatch(exportOSsRelatory(filters, 'laundryService/os-report'))
              }}
            >
              <Icon path={mdiMicrosoftExcel} size={1} />
              Download
            </IconButton>
          )}

          {renderRouteRole(['gerente_lavanderia'], role) && (
            <IconButton
              className={filterClasses.btnAdd}
              onClick={() => {
                handleCloseAllOs()
              }}
              style={{ backgroundColor: colors.error }}
            >
              Fechar Todas
            </IconButton>
          )}
        </Grid>
      </Grid>
    </BaseFilter>
  )
}
