/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import { post } from '../BillingActions'
import Form from './Form'
import { Grid, Slide } from '@material-ui/core'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import colors from '../../../assets/colors'

function NewBilling(props) {
  const { history, location } = props
  const dispatch = useDispatch()
  const [typeGeneric, setTypeGeneric] = useState('')
  const filters = useSelector(state => state.billing.filters)

  const onSubmit = async values => {
    const data = await dispatch(post(values, typeGeneric))
    if (data?.status === 200) {
      dispatch(fetchBilling(filters))
      history.goBack()
    }
  }

  const onClose = () => {
    props.history.push('/billing')
  }

  useEffect(() => {
    const { state } = location

    if (state?.typeGeneric) {
      setTypeGeneric(state?.typeGeneric)
    }
  }, [])

  return (
    <BaseFilterModal title="Novo Lançamento" open handleOpen={onClose}>
      <Grid container xs={12} item direction="row" style={{ width: '540px' }}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos abaixo para cadastrar uma nova conta
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form {...props} onClose={onClose} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default withStyles(styles)(NewBilling)
