import { Button, Grid, TextField } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import SelectField from '../../../../../components/SelectField'
import NumberFormatCustom from '../../../../../components/NumberFormat'
import filterStylesComponents from '../../../../../../assets/filterComponentsStyles'
import { post, update } from '../../../PartnersActions'
import { SNACKBAR } from '../../../../../main/MainActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const GeneralForm = ({ edit = false, mutate = () => {}, readOnly = false }) => {
  const paymentMethods = useSelector(state => state.laundries.paymentMethods)
  const laundry = useSelector(state => state.laundries.editingLaundry)

  const dispatch = useDispatch()
  const history = useHistory()
  const filterStyles = filterStylesComponents()

  const [form, setForm] = useState({
    id: '',
    name: '',
    company_name: '',
    contact_name: '',
    email: '',

    cep: '',
    street: '',
    number: '',
    complement: '',
    observations: '',
    neighborhood: '',
    city: '',
    uf: '',

    cnpj: '',
    phone: '',
    phone2: '',
    payment_method: '',
    payment_method_id: '',

    // default_value: 0,
    // urgent_value: 0,
  })

  const handleChange = e => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSearchCep = async () => {
    const currentCep = form?.cep?.replace(/\D/g, '')

    try {
      if (currentCep?.length === 8) {
        const resp = await axios
          .get(`https://viacep.com.br/ws/${currentCep}/json/`)
          .then(response => response?.data)

        setForm({
          ...form,
          city: resp?.localidade,
          uf: resp?.uf,
          street: resp?.logradouro,
          neighborhood: resp?.bairro,
        })
      }
    } catch (e) {
      // console.log(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)

    if (!form.payment_method_id) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: `O método de pagamento é obrigatório`,
        },
      })
    }

    data.append('id', form?.id)
    data.append('cnpj', form?.cnpj)
    data.append('phone', form?.phone)
    data.append('phone2', form?.phone2)
    data.append('payment_method_id', form?.payment_method_id)

    data.append('city', form?.city)
    data.append('uf', form?.uf)
    data.append('street', form?.street)
    data.append('neighborhood', form?.neighborhood)

    data.append('default_value', form?.default_value)
    data.append('urgent_value', form?.urgent_value)

    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      if (edit) {
        await dispatch(update(values, form?.id))

        dispatch({
          type: SNACKBAR.SOFTSUCCESS,
          success: {
            message: `Sucesso ao atualizar lavanderia!`,
          },
        })
        return mutate()
      }

      await dispatch(post(values))

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: {
          message: `Sucesso ao cadastrar lavanderia!`,
        },
      })

      handleClose()
    } catch (e) {
      console.log(e)

      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: `Erro ao ${edit ? 'atualizar' : 'cadastrar'} lavanderia!`,
        },
      })
    }
  }

  const handleClose = () => {
    history.push('/partners-module/partners')
  }

  useEffect(() => {
    if (!edit) return

    setForm({
      ...form,
      ...laundry,
    })
  }, [edit, laundry])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            required
            name="name"
            disabled={readOnly}
            label="Nome"
            fullWidth
            defaultValue={form?.name}
            key={`input-name-${form?.name}`}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            name="company_name"
            label="Razão Social"
            fullWidth
            disabled={readOnly}
            defaultValue={form?.company_name}
            key={`input-company_name-${form?.company_name}`}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            required
            name="contact_name"
            label="Nome do Contato"
            fullWidth
            disabled={readOnly}
            defaultValue={form?.contact_name}
            key={`input-contact_name-${form?.contact_name}`}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            required
            disabled={readOnly}
            name="email"
            label="E-mail"
            type="email"
            fullWidth
            defaultValue={form?.email}
            key={`input-email-${form?.email}`}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ReactInputMask
            mask="99.999-999"
            maskChar=""
            value={form?.cep}
            onChange={handleChange}
            disabled={readOnly}
            required
            onBlur={handleSearchCep}
          >
            {inputProps => (
              <TextField {...inputProps} label="CEP" fullWidth name="cep" />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            required
            name="street"
            label="Rua"
            fullWidth
            disabled={readOnly}
            key={`input-street-${form?.street}`}
            value={form?.street}
            onChange={handleChange}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            required
            name="number"
            label="Número"
            disabled={readOnly}
            key={`input-number-${form?.number}`}
            fullWidth
            type="number"
            defaultValue={form?.number}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            name="complement"
            label="Complemento"
            disabled={readOnly}
            key={`input-complement-${form?.complement}`}
            fullWidth
            defaultValue={form?.complement}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            name="neighborhood"
            key={`input-neighborhood-${form?.neighborhood}`}
            label="Bairro"
            fullWidth
            value={form?.neighborhood}
            disabled={readOnly}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            name="uf"
            key={`input-uf-${form?.uf}`}
            label="UF"
            fullWidth
            value={form?.uf}
            disabled
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ReactInputMask
            mask={'99.999.999/9999-99'}
            maskChar=""
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            value={form?.cnpj}
            onChange={handleChange}
            disabled={readOnly}
          >
            {inputProps => (
              <TextField {...inputProps} label="CNPJ" fullWidth name="cnpj" />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ReactInputMask
            mask={
              form?.phone?.replace(/[^\d]/g, '').length <= 10
                ? '(99) 9999-9999?'
                : '(99) 99999-9999'
            }
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            maskChar=""
            value={form?.phone}
            onChange={handleChange}
            required
            disabled={readOnly}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label="Telefone 1"
                fullWidth
                name="phone"
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ReactInputMask
            mask={
              form?.phone2?.replace(/[^\d]/g, '').length <= 10
                ? '(99) 9999-9999?'
                : '(99) 99999-9999'
            }
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            maskChar=""
            value={form?.phone2}
            disabled={readOnly}
            name="phone2"
            onChange={handleChange}
          >
            {inputProps => (
              <TextField {...inputProps} label="Telefone 2" fullWidth />
            )}
          </ReactInputMask>
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <SelectField
            style={{ color: 'black', marginTop: '0px' }}
            options={paymentMethods}
            setForm={e =>
              setForm({ ...form, payment_method_id: e?.id, payment_method: e })
            }
            data={form?.payment_method || null}
            uniqueState
            required
            name="payment_method_id"
            disabled={readOnly}
            label="Método de pagamento"
          />
        </Grid>

        {/* <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            value={form?.default_value}
            name="default_value"
            onChange={e => setForm({ ...form, default_value: e.target.value })}
            fullWidth
            disabled={readOnly}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                prefix: 'R$ ',
              },
            }}
            label="Valor m² tapete"
          />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            value={form?.urgent_value}
            disabled={readOnly}
            name="urgent_value"
            onChange={e => setForm({ ...form, urgent_value: e.target.value })}
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                prefix: 'R$ ',
              },
            }}
            label="Valor urgente m² tapete"
          />
        </Grid> */}

        <Grid item xs={12} style={{ marginTop: '2em' }}>
          <TextField
            placeholder="Observações"
            name="observations"
            disabled={readOnly}
            multiline
            key={`text-field-observations-${form?.observations}`}
            defaultValue={form?.observations}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '2em' }}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                type="button"
                onClick={handleClose}
                className={filterStyles.submitButton}
              >
                Fechar
              </Button>
            </Grid>

            <Grid item>
              {!readOnly && (
                <Button type="submit" className={filterStyles.submitButton}>
                  Salvar
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default GeneralForm
