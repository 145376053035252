/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import Clients from '../clients/pages/index'
import Dashboard from '../home/pages/index'
import Details from '../home/pages/Details'
import ServiceCategory from '../service-category/pages/index'
import ServiceType from '../service-type/pages/index'
import Users from '../users/pages/index'
import KnowWay from '../knowWay/pages/index'
import PaymentMethod from '../payment-type/pages/index'
import Attendance from '../attendance/pages/index'
import Calendar from '../callendar/pages/index'
import BirthRelatory from '../birthRelatory/pages/index'
import ClientsRelatory from '../clientsRelatory/pages/index'
import ProspectsRelatory from '../prospectsRelatory/pages/index'
import ServicesRelatory from '../servicesRelatory/pages/index'
import SchedulesRelatory from '../schedulesRelatory/pages/index'
import TeamProdutivityRelatory from '../teamProdutivityRelatory/pages/index'
import ServiceCategoriesRelatory from '../serviceCategoriesRelatory/pages/index'
import FinancialRelatory from '../financialRelatory/pages/index'
import Supplier from '../supplier/pages/index'
import BillingType from '../billingType/pages/index'
import Billing from '../billing/pages/index'
import FinancialDashboard from '../financialDashboard/pages/index'
import LaundryClient from '../laundryClients/pages/index'
import laundryClientBillings from '../laundryClientBillings/pages/index'
import ClientPlan from '../clientPlan/pages/index'
import Bank from '../bank/pages/index'
import BankReport from '../bank-report/pages/index'
import Settings from '../settings/pages/index'
import EditLaundry from '../edit-laundryClient'
import LeadsDashboard from '../leadsDashboard/pages/index'
import ReadyItems from '../home/pages/ReadyItems'
import Edit from '../attendance/pages/Edit'
import AttendanceNew from '../attendance/pages/New'
import NewClient from '../clients/pages/New'
import EditClient from '../clients/pages/Edit'
import NewUser from '../users/pages/New'
import EditUser from '../users/pages/Edit'
import Subscription from '../subscription/pages'
import NewLaundryClient from '../laundryClients/pages/New'
import EditLaundryClient from '../laundryClients/pages/Edit'
import Stock from '../stock/pages'
import StockRelatory from '../StockRelatory/pages'
import ProductHistory from '../productHistory/pages'
import RelatoryCommission from '../relatoryCommission/pages'
import Produtivity from '../teamProdutivityRelatory/pages/Produtivity'
import LaundryEletronicInvoice from '../laundry-eletronic-invoice/pages'
import EletronicInvoice from '../attendance/pages/EletronicInvoice'
import Triggers from '../crm/triggers/pages'
import CrmPanel from '../crm/panel/pages'
import LeadsReport from '../crm/reports/leads/pages'
import GerencyReport from '../crm/reports/gerency/pages'
import Dre from '../dre/pages'
import Laundries from '../partners-module/partners/pages/index.jsx'
import NewLaundry from '../partners-module/partners/pages/Components/New'
import EditLaundryPage from '../partners-module/partners/pages/Components/Edit.js'
import Visualization from '../partners-module/partners/pages/Components/Visualization.js'

import PartnersOss from '../partners-module/oss/pages/index.js'
import OsDetails from '../partners-module/oss/pages/Details/Details.js'
import PartnersDashboard from '../partners-module/dashboard/pages/index'

import ItemsReport from '../partners-module/items-report/pages/index'
import ItemHistory from '../attendance/pages/ItemHistory.js'

function findPermission(roles, permissions) {
  if (!roles) return 0
  return permissions.filter(x => x.name === roles[0]).length
}

function findEmail(emails, user) {
  if (!emails) return 0
  return emails?.filter(email => user?.email === email).length
}

function findRoles(roles, userRole) {
  if (!roles) return 0
  return roles?.filter(role => userRole?.name === role).length
}

const redirectByRole = userRole => {
  if (userRole?.name === 'lavanderia')
    return <Redirect to="/laundry-dashboard" />

  return <Redirect to="/" />
}

export const redirectByPermission = permission => {
  if (permission[0]?.name === 'read-finance-module') {
    return <Redirect to="/financial" />
  }
}

const PrivateRouteWrapper = props => {
  const {
    permissions,
    role,
    emails,
    user,
    userRole,
    rolesAllowed,
    ...routeProps
  } = props
  if (
    findPermission(role, permissions) ||
    findEmail(emails, user) ||
    findRoles(rolesAllowed, userRole)
  ) {
    return <Route {...routeProps} />
  }
  return redirectByRole(userRole)
}

const PrivateRoute = connect(state => ({
  permissions: state.auth.permissions,
  user: state.auth.user,
  userRole: state.auth.role,
}))(PrivateRouteWrapper)

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        role={['create-users']}
        path="/users/new"
        component={NewUser}
      />

      <PrivateRoute
        role={['create-laundry-client']}
        path="/laundry-clients/new"
        component={NewLaundryClient}
      />
      <PrivateRoute
        role={['update-laundry-client']}
        path="/laundry-clients/:id/edit"
        component={EditLaundryClient}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/crm/triggers"
        component={Triggers}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/crm/panel"
        component={CrmPanel}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/partners-module/partners"
        component={Laundries}
      />

      <PrivateRoute
        role={['create-attendances']}
        path="/crm/reports/leads"
        component={LeadsReport}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/crm/reports/gerency"
        component={GerencyReport}
      />
      <PrivateRoute
        role={['gerency-update-laundry-client']}
        path="/attendance/eletronic-invoice/:id"
        component={EletronicInvoice}
      />
      <PrivateRoute
        role={['update-users']}
        path="/users/:id/edit"
        component={EditUser}
      />
      <PrivateRoute
        role={['create-users']}
        path="/users/new"
        component={NewClient}
      />

      <PrivateRoute
        role={['read-team-produtivity-relatory']}
        path="/relatory-team-produtivity/:id"
        component={Produtivity}
      />
      <Route path="/users/:id/see/:see" component={EditUser} />
      <PrivateRoute
        exact
        role={['read-dashboard']}
        path="/"
        component={Dashboard}
      />

      <PrivateRoute
        role={['read-ready-items']}
        path="/details/ready"
        component={ReadyItems}
      />
      <PrivateRoute
        role={['read-financial-dashboard']}
        path="/dashboard-financial"
        component={FinancialDashboard}
      />
      <PrivateRoute
        role={['read-lead-dashboard']}
        path="/lead"
        component={LeadsDashboard}
      />
      <Route path="/details/:id" component={Details} />
      <Route path="/details/late" component={Details} />
      <PrivateRoute role={['read-users']} path="/users" component={Users} />
      <PrivateRoute
        role={['create-attendances']}
        path="/attendance/new"
        component={AttendanceNew}
      />
      <PrivateRoute
        role={['update-attendances']}
        path="/attendance/:id/edit"
        component={Edit}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/service-item/history/:id"
        component={ItemHistory}
      />

      <PrivateRoute
        role={['create-client']}
        path="/clients/new"
        component={NewClient}
      />
      <PrivateRoute
        role={['update-client']}
        path="/clients/:id/edit"
        component={EditClient}
      />
      <PrivateRoute
        role={['read-know-way']}
        path="/knowWay"
        component={KnowWay}
      />
      <PrivateRoute
        role={['read-service-categories']}
        path="/service-category"
        component={ServiceCategory}
      />
      <PrivateRoute
        role={['read-laundry-client']}
        path="/laundry-clients"
        component={LaundryClient}
      />
      <PrivateRoute
        role={['laundry-client-billing-list']}
        path="/laundry-client-billings"
        component={laundryClientBillings}
      />
      <PrivateRoute role={['read-billing']} path="/dre" component={Dre} />
      <PrivateRoute
        role={['read-client']}
        path="/clients"
        component={Clients}
      />
      <PrivateRoute
        role={['read-service-types']}
        path="/service-type"
        component={ServiceType}
      />
      <PrivateRoute
        role={['read-payment-methods']}
        path="/payment-method"
        component={PaymentMethod}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/attendance"
        component={Attendance}
      />
      <PrivateRoute
        role={['read-schedule']}
        path="/calendar/:id?"
        component={Calendar}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/stock"
        component={Stock}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/product-history/:id"
        component={ProductHistory}
      />
      <PrivateRoute
        role={['read-birth-relatory']}
        path="/relatory-birth"
        component={BirthRelatory}
      />
      <PrivateRoute
        role={['read-birth-relatory']}
        path="/relatory-stock"
        component={StockRelatory}
      />
      <PrivateRoute
        role={['read-birth-relatory']}
        path="/relatory-commission"
        component={RelatoryCommission}
      />
      <PrivateRoute
        role={['read-clients-relatory']}
        path="/relatory-clients"
        component={ClientsRelatory}
      />
      <PrivateRoute
        role={['read-prospects-relatory']}
        path="/relatory-prospects"
        component={ProspectsRelatory}
      />
      <PrivateRoute
        role={['read-attendances-relatory']}
        path="/relatory-services"
        component={ServicesRelatory}
      />
      <PrivateRoute
        role={['read-schedule-relatory']}
        path="/relatory-schedules"
        component={SchedulesRelatory}
      />
      <PrivateRoute
        role={['read-service-category-relatory']}
        path="/relatory-service-category"
        component={ServiceCategoriesRelatory}
      />
      <PrivateRoute
        role={['read-team-produtivity-relatory']}
        path="/relatory-team-produtivity"
        component={TeamProdutivityRelatory}
      />
      {/* <PrivateRoute
        role={['read-financial-relatory']}
        path="/relatory-financial"
        component={FinancialRelatory}
      /> */}
      <PrivateRoute
        role={['read-supplier']}
        path="/supplier"
        component={Supplier}
      />
      <PrivateRoute
        role={['read-billing-type']}
        path="/billing-type"
        component={BillingType}
      />
      <PrivateRoute
        role={['read-billing']}
        path="/billing"
        component={Billing}
      />
      <PrivateRoute
        role={['client-plan-list']}
        path="/client-plan"
        component={ClientPlan}
      />
      {/* <PrivateRoute
        role={['read-bank-report']}
        path="/bank-report"
        component={BankReport}
      /> */}

      <PrivateRoute role={['read-bank']} path="/bank" component={Bank} />

      <PrivateRoute
        role={['change-status-settings', 'update-doc-model', 'download-apk']}
        path="/settings"
        component={Settings}
      />
      <PrivateRoute
        role={['gerency-update-laundry-client']}
        path="/edit-laundry"
        component={EditLaundry}
      />
      <PrivateRoute
        role={['gerency-update-laundry-client']}
        path="/laundry-eletronic-invoice"
        component={LaundryEletronicInvoice}
      />
      <PrivateRoute
        role={['gerency-update-laundry-client']}
        path="/subscription"
        component={Subscription}
      />

      {/* Partners Module */}
      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/partner/new"
        component={NewLaundry}
      />

      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/partner/edit/:id"
        component={EditLaundryPage}
      />

      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/partner/visualization/:id"
        component={Visualization}
      />

      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/oss"
        component={PartnersOss}
      />

      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/os/:id"
        component={OsDetails}
      />

      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/dashboard"
        component={PartnersDashboard}
      />

      <PrivateRoute
        role={['laundry-module']}
        path="/partners-module/items-report"
        component={ItemsReport}
      />
      {/* Partners Module */}

      <PrivateRoute exact role={['read-finance-module']} path="/financial" />
      {redirectByRole()}
    </Switch>
  )
}

export default React.memo(Routes)
