import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  Typography,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { useParams, useHistory } from 'react-router-dom'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import {
  edit,
  editServiceItem,
  updateServiceItem,
} from '../../PartnerAttendanceActions'
import colors from '../../../../../assets/colors'
import NumberFormatCustom from '../../../../components/NumberFormat'

const EditItem = ({}) => {
  const serviceItem = useSelector(
    state => state.partnerAttendances.editServiceItem
  )
  const showingAttendanceFilter = useSelector(
    state => state.partnerAttendances.showingAttendanceFilter
  )

  const { serviceItem: serviceItemId, id } = useParams()

  const [form, setForm] = useState({
    bar_code: '',
    options: { type: '', base: '', altura: '', description: '', diametro: '' },
    quantity: '',
  })

  const dispatch = useDispatch()
  const history = useHistory()

  const filterStyles = filterStylesComponents()

  const fetchEditItem = async () => {
    dispatch(editServiceItem(serviceItemId))
  }

  const handleClose = () => {
    history.goBack()
  }

  const init = () => {
    setForm({
      bar_code: serviceItem?.bar_code,
      options: serviceItem?.options,
      quantity: serviceItem?.quantity,
    })
  }

  const handleChangeOptions = e => {
    const { name, value } = e.target

    setForm({
      ...form,
      options: {
        ...form?.options,
        [name]: value,
      },
    })
  }

  const handleChange = e => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    const resp = await dispatch(updateServiceItem(serviceItemId, form))

    if (!resp?.data) return

    await dispatch(edit(id, showingAttendanceFilter))
    handleClose()
  }

  useEffect(() => {
    if (!serviceItem) return
    init()
  }, [serviceItem])

  useEffect(() => {
    fetchEditItem()
  }, [serviceItemId])

  return (
    <BaseFilterModal
      open
      title={`Editar Item`}
      handleOpen={handleClose}
      hiddenHelpButton
    >
      <Grid item xs={12} style={{ width: '550px' }}>
        <form onSubmit={handleSubmitForm}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: '12px', color: colors.grayMedium }}
              >
                Serviço: {serviceItem?.serviceType?.name}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Etiqueta"
                value={form?.bar_code || ''}
                name="bar_code"
                onChange={handleChange}
                required
              />
            </Grid>

            {serviceItem?.serviceType?.measure_type_id != 2 && (
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Quantidade"
                      name="quantity"
                      value={form?.quantity}
                      onChange={e =>
                        setForm({ ...form, quantity: e.target.value })
                      }
                      required
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {serviceItem?.serviceType?.measure_type_id == 2 && (
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Tipo de medida"
                      required
                      value={form?.options?.type}
                      key={`select-type-${form?.options?.type}`}
                      onChange={handleChangeOptions}
                      name="type"
                      select
                    >
                      <MenuItem value={'1'} key={'1'}>
                        Quadrado
                      </MenuItem>
                      <MenuItem value={'2'} key={'2'}>
                        Redondo
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {form?.options?.type == 1 && (
                      <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                          {/* <TextField fullWidth label="Base" required /> */}
                          <TextField
                            label="Base"
                            value={form?.options?.base}
                            onChange={e =>
                              setForm({
                                ...form,
                                options: {
                                  ...form.options,
                                  base: e.target.value,
                                },
                              })
                            }
                            required
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Altura"
                            value={form?.options?.altura}
                            onChange={e =>
                              setForm({
                                ...form,
                                options: {
                                  ...form.options,
                                  altura: e.target.value,
                                },
                              })
                            }
                            required
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {form?.options?.type == 2 && (
                      <Grid
                        container
                        spacing={4}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Diametro"
                            required
                            name="diametro"
                            onChange={handleChangeOptions}
                            value={form?.options?.diametro}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                label="Observações"
                value={form?.options?.description || ''}
                onChange={handleChangeOptions}
                name="description"
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginTop: '1em' }}
                className={filterStyles.areaButtons}
              >
                <Grid item>
                  <Button className={filterStyles.btnMoreFilters}>
                    Fechar
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" className={filterStyles.btnSalvar}>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default EditItem
