import {
  FETCH_ATTENDANCE_PARTNERS,
  EDIT_ATTENDANCE_LAUNDRY,
  EDIT_CLIENT_ADDRESS,
  EDIT_SERVICE_ITEM,
  // CLOSE_LAUNDRY_OS,
  // TOOGLE_SHOW_IMAGES,
  // SEND_MAIL_BILLING,
  // REOPEN_LAUNDRY_OS,
} from './PartnerAttendanceActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  filters: {},
  edditingClientAddress: {},
  editServiceItem: {},
  showingAttendanceFilter: {},
  showingAttendance: null,
  totals: {},
  today: {},
  statuses: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_PARTNERS.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        meta: action.payload.data.meta,
        filters: action.payload.filters,
        totals: action.payload.data.totals,
        today: action.payload.data.today,
        statuses: action.payload.data.statuses,
      }
    case EDIT_ATTENDANCE_LAUNDRY.SUCCESS:
      return {
        ...state,
        showingAttendance: action.payload.data?.data,
        showingAttendanceFilter: action.payload.filters,
      }
    case EDIT_CLIENT_ADDRESS.SUCCESS:
      return {
        ...state,
        edditingClientAddress: action.payload.data,
      }
    case EDIT_SERVICE_ITEM.SUCCESS:
      return {
        ...state,
        editServiceItem: action.payload.data,
      }
    // case CLOSE_LAUNDRY_OS.SUCCESS:
    //   return {
    //     ...state,
    //     items: state.items.map(item => {
    //       if (item.id === action.payload.data.id) {
    //         return action.payload.data
    //       }

    //       return item
    //     }),
    //   }
    // case REOPEN_LAUNDRY_OS.SUCCESS:
    //   return {
    //     ...state,
    //     items: state.items.map(item => {
    //       if (item.id === action.payload.id) {
    //         return action.payload.data.data
    //       }

    //       return item
    //     }),
    //   }
    // case TOOGLE_SHOW_IMAGES.SUCCESS:
    //   const attendances = { ...state.showingAttendance }
    //   attendances.items.map(item => {
    //     if (item.id === action.payload.id) {
    //       item.show_images = !item.show_images
    //     }

    //     return item
    //   })
    //   return {
    //     ...state,
    //     showingAttendance: attendances,
    //   }
    // case SEND_MAIL_BILLING.SUCCESS:
    //   return {
    //     ...state,
    //     items: state.items.map(item => {
    //       if (item.id === action.payload.id) item.bill_sended = 1

    //       return item
    //     }),
    //   }
    // case 'ATTENDANCE_LAUNDRY_CLEAR_FILTERS':
    //   return { ...state, filters: {} }
    default:
      return state
  }
}
