import axios from 'axios'
import config from '../../../config'
import action, { actionFailed } from '../../../util/action'

import createAsyncConst from '../../../util/createAsyncConst'

export const FETCH_LAUNDRY_DASHBOARD = createAsyncConst(
  'FETCH_LAUNDRY_DASHBOARD'
)

// export const FETCH_HISTORIC_ITEM_LAUNDRY = createAsyncConst(
//   'FETCH_HISTORIC_ITEM_LAUNDRY'
// )

export function fetchServiceItems(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY_DASHBOARD.ACTION))
    return axios
      .get(`${config.API_URL}/laundry-service/report`, {
        params: filters,
      })
      .then(resp => {
        dispatch(
          action(FETCH_LAUNDRY_DASHBOARD.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY_DASHBOARD, e)))
  }
}

// export function getItemHistory(id) {
//   return dispatch => {
//     dispatch(action(FETCH_HISTORIC_ITEM_LAUNDRY.ACTION))
//     return axios
//       .get(`${config.API_URL}/service-item/history/${id}`)
//       .then(resp => resp.data)
//       .then(data => {
//         dispatch(action(FETCH_HISTORIC_ITEM_LAUNDRY.SUCCESS, data))
//         return data
//       })
//       .catch(e => dispatch(actionFailed(FETCH_HISTORIC_ITEM_LAUNDRY, e)))
//   }
// }

// export function clearFilters() {
//   return {
//     type: 'LAUNDRY_DASHBOARD_CLEAR_FILTERS',
//   }
// }

export function makeReport(filters) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/laundry-service/report`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: '*/*' },
      params: filters,
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'Relatorio Dashboard Lavanderia.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e =>
        dispatch({
          type: SNACKBAR.HARDFAIL,
          error: { message: 'Erro na geração do arquivo.' },
        })
      )
  }
}
