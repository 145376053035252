/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable eqeqeq */
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchDashboard,
  fetchFinancialDashboard,
} from '../PartnersModuleActions'
import styles from '../../../../resources/theme/global'

import PeriodFilter from '../../../../components/hooks/BaseButtonGroup'
import { format } from 'date-fns'
import colors from '../../../../assets/colors'
import Tabs from '../../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../../components/hooks/BaseTabs/Tab'
import {
  mdiCalendarCheck,
  mdiCalendarMultipleCheck,
  mdiMenuLeft,
  mdiMenuRight,
} from '@mdi/js'
import Icon from '@mdi/react'
import { ptBR } from 'date-fns/locale'
import CardServiceType from './Card'

const useStyles = makeStyles({
  boxChart: {
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    padding: '12px',
    fontSize: '14px',
    lineHeight: '14px',
    height: '100%',
    color: colors.gray,
  },
  boxChartTitle: {
    fontSize: '20px',
    lineHeight: '20px',
    color: colors.primary,
  },
  filterBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
  },
  periodFilter: {
    color: colors.primary,
    display: 'flex',
    minWidth: '210px',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '14px',
  },
  periodFilterBtn: {
    padding: 0,
    transition: 'all .4s',
    color: colors.primary,
    '&:hover': {
      opacity: '.7',
      backgroundColor: '#FFF',
    },
  },
})

function PartnersDashboard(props) {
  const styles = useStyles()
  const dispatch = useDispatch()

  const data = useSelector(state => state.partnersDashboard.data)
  const laundries = useSelector(state => state.partnersDashboard.laundries)

  const [form, setForm] = useState({
    date: new Date(),
    laundry_id: null,
  })
  const [value, setValue] = useState(0)

  const handleChangeMonth = (prev = false) => {
    if (prev) return new Date(form?.date.setMonth(form?.date.getMonth() - 1))
    return new Date(form?.date.setMonth(form?.date.getMonth() + 1))
  }

  const handleChangeYear = (prev = false) => {
    if (prev)
      return new Date(form?.date.setFullYear(form?.date.getFullYear() - 1))
    return new Date(form?.date.setFullYear(form?.date.getFullYear() + 1))
  }

  // control date by tab value = 1 == Anual
  const handleChangeDate = (prev = false) => {
    switch (value) {
      case 0:
        return setForm({ ...form, date: handleChangeMonth(prev) })
      case 1:
        return setForm({ ...form, date: handleChangeYear(prev) })
      default:
        break
    }
  }

  const onClickNext = () => {
    handleChangeDate()
  }

  const onClickPrev = () => {
    handleChangeDate(true)
  }

  useEffect(() => {
    dispatch(fetchDashboard({ isYear: value == 2, ...form }))
  }, [form])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Dashboard de Parceiros
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Tabs>
          <Tab
            label="Mensal"
            icon={mdiCalendarCheck}
            value={value}
            setValue={setValue}
            tabIndex={0}
          />
          <Tab
            label="Anual"
            icon={mdiCalendarMultipleCheck}
            value={value}
            setValue={setValue}
            tabIndex={1}
            removeNavigation={false}
          />
        </Tabs>
      </Grid>

      {value == 0 && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <Box className={styles.filterBox}>
                <span>Selecione um mês:</span>

                <Box className={styles.periodFilter}>
                  <IconButton
                    onClick={onClickPrev}
                    className={styles.periodFilterBtn}
                  >
                    <Icon path={mdiMenuLeft} size={0.7} />
                  </IconButton>

                  <Typography>
                    {format(form?.date, "MMMM 'de' yyyy", { locale: ptBR })}
                  </Typography>

                  <IconButton
                    onClick={onClickNext}
                    className={styles.periodFilterBtn}
                  >
                    <Icon path={mdiMenuRight} size={0.7} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12}>
                  <TextField
                    select
                    value={form.laundry_id}
                    onChange={e =>
                      setForm({ ...form, laundry_id: e.target.value })
                    }
                    fullWidth
                    label="Filtre por Lavanderia"
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {laundries?.map((laundry, index) => (
                      <MenuItem value={laundry?.id} key={index}>
                        {laundry?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {value == 1 && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Box className={styles.filterBox}>
                  <span>Selecione um ano:</span>

                  <Box className={styles.periodFilter}>
                    <IconButton
                      onClick={onClickPrev}
                      className={styles.periodFilterBtn}
                    >
                      <Icon path={mdiMenuLeft} size={0.7} />
                    </IconButton>

                    <Typography>
                      {format(form?.date, 'yyyy', { locale: ptBR })}
                    </Typography>

                    <IconButton
                      onClick={onClickNext}
                      className={styles.periodFilterBtn}
                    >
                      <Icon path={mdiMenuRight} size={0.7} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <TextField
                      select
                      value={form.laundry_id}
                      onChange={e =>
                        setForm({ ...form, laundry_id: e.target.value })
                      }
                      fullWidth
                      label="Filtre por Lavanderia"
                    >
                      <MenuItem value="">Selecione...</MenuItem>
                      {laundries?.map((laundry, index) => (
                        <MenuItem value={laundry?.id} key={index}>
                          {laundry?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Grid container spacing={4}>
          {data?.map((serviceType, index) => (
            <CardServiceType
              key={index}
              name={serviceType?.name}
              qtd={serviceType?.quantity}
              orcament={serviceType?.value}
              unitQtd={serviceType?.unit_qtd}
              measureType={serviceType?.measure_type}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(PartnersDashboard)
