import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputBase,
  TextField,
} from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import SelectField from '../../../../../components/SelectField'
import colors from '../../../../../../assets/colors'
import filterStylesComponents from '../../../../../../assets/filterComponentsStyles'
import { mdiAccountMinus, mdiAccountPlus, mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import { newAppointment } from '../../../PartnersActions'
import { SNACKBAR } from '../../../../../main/MainActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import { useConfirm } from 'material-ui-confirm'

const AppointmentForm = ({
  mutate = () => {},
  readOnly = false,
  editingId = null,
}) => {
  const streetAttendants = useSelector(
    state => state.laundries.streetAttendants
  )
  const weeks = useSelector(state => state.laundries.weeks)
  const editingLaundry = useSelector(state => state.laundries.editingLaundry)

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const history = useHistory()
  const filterStyles = filterStylesComponents()

  const [appointment, setAppointment] = useState({
    attendant_id: '',
    attendant: {},
    date_time: new Date(),
  })

  const handleClose = () => {
    history.push('/laundries')
  }

  const confirmNewAvulso = () => {
    confirm({
      description: `Deseja gerar um atendimento avulso para a data ${format(
        appointment?.date_time,
        'dd/MM/yyyy HH:mm:ss'
      )}?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      const data = {
        ...appointment,
        date_time: format(appointment?.date_time, 'yyyy-MM-dd HH:mm:ss'),
      }

      dispatch(newAppointment(data, editingId))
      setAppointment({
        attendant_id: '',
        attendant: {},
        date_time: new Date(),
      })
    })
  }

  const handleCreateAppointment = async () => {
    if (!appointment?.attendant_id) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: 'Selecione o atendente',
        },
      })
    }

    if (appointment?.date_time < new Date()) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: 'A data não pode ser menor que a atual',
        },
      })
    }

    confirmNewAvulso()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={2}>
                <Avatar
                  style={{
                    backgroundColor:
                      appointment?.attendant?.color || colors?.primary,
                  }}
                >
                  {appointment?.attendant?.name
                    ? appointment?.attendant?.name?.charAt(0)
                    : ''}
                </Avatar>
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  disabled={readOnly}
                  style={{ color: 'black', marginTop: '0px' }}
                  options={streetAttendants}
                  setForm={attendant => {
                    setAppointment({
                      ...appointment,
                      attendant: attendant,
                      attendant_id: attendant?.id,
                    })
                  }}
                  data={appointment?.attendant || null}
                  uniqueState
                  label="Técnico"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <KeyboardDateTimePicker
                  clearable
                  label="Selecione um dia e horario:"
                  views={['date', 'hours', 'minutes']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  disablePast
                  value={appointment.date_time}
                  onChange={date => {
                    setAppointment({ ...appointment, date_time: date })
                  }}
                  format="dd/MM/yyyy HH:mm"
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      type="button"
                      className={filterStyles.btnSalvar}
                      onClick={handleCreateAppointment}
                    >
                      <Icon path={mdiAccountPlus} size={1} />
                      Adicionar
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AppointmentForm
