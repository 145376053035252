import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputBase,
  TextField,
} from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectField from '../../../../../components/SelectField'
import colors from '../../../../../../assets/colors'
import filterStylesComponents from '../../../../../../assets/filterComponentsStyles'
import { mdiAccountMinus, mdiAccountPlus, mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import { post, syncAttendants, update } from '../../../PartnersActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AutoComplete from '../../../../../components/Autocomplete'

const AttendancesForm = ({
  mutate = () => {},
  readOnly = false,
  editingId = null,
}) => {
  const streetAttendants = useSelector(
    state => state.laundries.streetAttendants
  )
  const weeks = useSelector(state => state.laundries.weeks)
  const editingLaundry = useSelector(state => state.laundries.editingLaundry)

  const dispatch = useDispatch()
  const history = useHistory()
  const filterStyles = filterStylesComponents()

  const [attendants, setAttendants] = useState([])

  const handleClose = () => {
    history.push('/laundries')
  }

  const hanldeAddAttendant = () => {
    const starterAttendant = {
      attendant_id: '',
      attendant: {},
      week_days_id: [],
      week_days: [],
    }

    const newAttendants = [...attendants]
    newAttendants.push(starterAttendant)
    setAttendants(newAttendants)
  }

  const handleSelectAttendant = (attendant, index) => {
    const newAttendants = [...attendants]
    newAttendants[index] = {
      ...newAttendants[index],
      attendant_id: attendant?.id,
      attendant: attendant,
    }
    setAttendants(newAttendants)
  }

  const handleSelectAttendantWeek = (weeks, index) => {
    const newAttendants = [...attendants]

    const ids = weeks?.map(week => week?.id)
    newAttendants[index] = {
      ...newAttendants[index],
      week_days: weeks,
      week_days_id: ids,
    }
    setAttendants(newAttendants)
  }

  const handleDeleteAttendant = index => {
    const newAttendants = [...attendants]
    newAttendants.splice(index, 1)
    setAttendants(newAttendants)
  }

  const handleSyncAttendants = async () => {
    try {
      dispatch(syncAttendants({ attendants: attendants }, editingId))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setAttendants(editingLaundry?.attendants || [])
  }, [editingLaundry])

  return (
    <Grid container spacing={2}>
      {/* <Avatar>M</Avatar> */}
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          {!readOnly && (
            <IconButton
              type="button"
              className={filterStyles.btnSalvar}
              onClick={hanldeAddAttendant}
            >
              <Icon path={mdiAccountPlus} size={1} />
              Adicionar
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {attendants?.map((attendant, index) => (
            <Grid item xs={12} key={index}>
              <Grid item container spacing={2} alignItems="flex-end">
                <Grid item xs={2}>
                  <Avatar
                    style={{
                      backgroundColor:
                        attendant?.attendant?.color || colors?.primary,
                    }}
                  >
                    {attendant?.attendant?.name
                      ? attendant?.attendant?.name?.charAt(0)
                      : ''}
                  </Avatar>
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    disabled={readOnly}
                    style={{ color: 'black', marginTop: '0px' }}
                    options={streetAttendants}
                    setForm={attendant =>
                      handleSelectAttendant(attendant, index)
                    }
                    data={attendant?.attendant || null}
                    uniqueState
                    label="Técnico"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutoComplete
                    readOnly={readOnly}
                    options={weeks}
                    label="Dias da Semana"
                    data={attendant?.week_days}
                    updateFunction={weeks =>
                      handleSelectAttendantWeek(weeks, index)
                    }
                    limitTags={8}
                    name="weeks"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      {!readOnly && (
                        <IconButton
                          type="button"
                          className={filterStyles.btnSalvar}
                          onClick={() => {
                            handleDeleteAttendant(index)
                          }}
                        >
                          <Icon path={mdiAccountMinus} size={1} />
                          Remover
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '2em' }}>
        <Grid container xs={12} justifyContent="flex-end">
          <Grid item>
            {attendants?.length > 0 && !readOnly && (
              <Button
                type="button"
                className={filterStyles.submitButton}
                onClick={handleSyncAttendants}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AttendancesForm
