import { Avatar, Button, Grid, IconButton, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../../../assets/colors'
import filterStylesComponents from '../../../../../../assets/filterComponentsStyles'
import { mdiAccountMinus, mdiAccountPlus, mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import { getAppointments, removeAppointments } from '../../../PartnersActions'
import { useConfirm } from 'material-ui-confirm'

const LastAppointmentsForm = ({
  mutate = () => {},
  readOnly = false,
  editingId = null,
}) => {
  const appointments = useSelector(state => state.laundries.appointments)

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const filterStyles = filterStylesComponents()

  const remove = id => {
    confirm({
      description: `Deseja realmente excluir o compromisso?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(removeAppointments(id)).then(() =>
        dispatch(getAppointments(editingId))
      )
    })
  }

  useEffect(() => {
    if (!editingId) return

    dispatch(getAppointments(editingId))
  }, [])

  return (
    <Grid container spacing={2}>
      {appointments?.map((appointment, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item container spacing={2} alignItems="flex-end">
                <Grid item xs={2}>
                  <Avatar
                    style={{
                      backgroundColor:
                        appointment?.attendant?.color || colors?.primary,
                    }}
                  >
                    {appointment?.attendant?.name
                      ? appointment?.attendant?.name?.charAt(0)
                      : ''}
                  </Avatar>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={appointment?.attendant?.name}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={appointment?.date_formmated}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      {appointment?.isDeleted ? (
                        <IconButton
                          type="button"
                          className={filterStyles.btnSalvar}
                        >
                          Deletado
                        </IconButton>
                      ) : (
                        <IconButton
                          type="button"
                          className={filterStyles.btnSalvar}
                          onClick={() => remove(appointment?.id)}
                        >
                          <Icon path={mdiAccountMinus} size={1} />
                          Deletar
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default LastAppointmentsForm
