/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../../components/hooks/BaseTableComponents'
import {
  mdiAlert,
  mdiClipboardTextClockOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiNoteEdit,
  mdiNoteEditOutline,
  mdiTrophyOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../../assets/colors'
import Pagination from '../../../../../components/hooks/BasePagination'

const List = ({ form, setForm }) => {
  const items = useSelector(state => state.leadsReport.items)
  const meta = useSelector(state => state.leadsReport.meta)

  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    setPaginate({
      ...paginate,
      page: newPage,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left" style={{ width: '20%' }}>
                Atendente
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Cadenciados
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Finalizados
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Vendidos
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Conversão
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Ranking
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((attedant, index) => (
              <TableRow key={attedant?.id} hover>
                <StyledTableCellBody align="left">
                  {attedant?.name}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {attedant?.leads_captured}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {attedant?.leads_finalized}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {attedant?.leads_sold}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {attedant?.converted_percent || 0}%
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  <Box
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      path={mdiTrophyOutline}
                      size={1}
                      color={colors.primary}
                    />
                    <span>{attedant?.ranking}</span>
                  </Box>
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination
          from={meta?.from}
          to={meta?.to}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={paginate?.per_page}
          rows={meta?.total}
          page={paginate?.page}
          handleChangePage={handleChangePage}
        />
      </TableContainer>
    </>
  )
}

export default List
