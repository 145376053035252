import axios from 'axios/index'

import config from '../../../config'
import action, { actionFailed } from '../../../util/action'
import createAsyncConst from '../../../util/createAsyncConst'

export const FETCH_DASHBOARD = createAsyncConst('FETCH_DASHBOARD')

export function fetchDashboard(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_DASHBOARD.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/laundry-service/dashboard`, {
        params: filters,
      })
      .then(resp => {
        dispatch(action(FETCH_DASHBOARD.SUCCESS, resp.data, false, false))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_DASHBOARD, e, false, false)))
  }
}
