/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  mdiCurrencyUsd,
  mdiCurrencyUsdOff,
  mdiFileDocumentCheckOutline,
} from '@mdi/js'
import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { clearFilter, fetchBilling, getBillings } from '../BillingActions'
import { getBillingListData } from '../BillingReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'
import CloseDetails from './CloseDetails'
import colors from '../../../assets/colors'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import { getPermission } from '../../attendance/AttendanceActions'

import BalanceList from './BalanceList'
import BankReportFilter from './BankReportFilter'
import BankTable from './BankTable'

import queryString from 'query-string'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'

import { useLocation } from 'react-router-dom'

const Billing = props => {
  const { location } = props

  const items = useSelector(state => state.billing.items)
  const historic = useSelector(state => state.billing.bankReport)
  const filters = useSelector(state => state.billing.filters)
  const permissions = getPermission()

  const dispatch = useDispatch()

  const params = useLocation()

  const [value, setValue] = useState(null)

  const [form, setForm] = useState({
    person: '',
    date: null,
    date_end: null,
    date_payment: null,
    date_end_payment: null,
    status: '',
    billing_type: '',
    payment_method: '',
    service_id: '',
    company: '',
    bank: '',
    reference_month: '',
    period: '',
    // billing_type_generic: 'Despesa',
    // type: 'Despesa',
    // ...filters,
  })

  const verifyRouterParams = () => {
    const objectParams = queryString.parse(params.search)

    let filters = {}

    if (objectParams?.type) {
      filters = {
        ...form,
        billing_type_generic: objectParams?.type,
        ...objectParams,
      }
    } else if (params.search) {
      filters = {
        ...form,
        type: 'Despesa',
        billing_type_generic: 'Despesa',
      }
    } else {
      filters = {
        ...form,
        type: 'Despesa',
        billing_type_generic: 'Despesa',
      }
    }

    console.log('Use Effect Params', filters)
    setForm(filters)
  }

  const fetchData = async () => {
    const resp = await dispatch(fetchBilling(form))

    if (form?.type == 'Receita') setValue(1)
    if (form?.type == 'Despesa') setValue(0)
  }

  useEffect(() => {
    if (!form?.billing_type_generic || !form.type) return
    fetchData()
  }, [form])

  useEffect(() => {
    if (!form?.billing_type_generic || !form.type) return

    setForm({
      ...form,
      type: value == 0 ? 'Despesa' : 'Receita',
      billing_type_generic: value == 0 ? 'Despesa' : 'Receita',
    })
  }, [value])

  // useEffect(() => {
  //   verifyRouterParams()
  // }, [params])

  useEffect(() => {
    verifyRouterParams()
  }, [])

  // useEffect(() => {
  //   if (filters?.type) {
  //     setForm({
  //       ...form,
  //       type: form?.type,
  //       billing_type_generic: form?.type,
  //     })
  //   }
  // }, [filters])

  // useEffect(() => {
  //   if (!items?.length) return
  //   console.log('Items', items)
  // }, [items])

  // useEffect(() => {
  //   console.log('Items', filters)
  // }, [filters])

  // useEffect(() => {
  //   let filters = form

  //   if (location?.search) {
  //     const params = queryString.parse(location.search)

  //     if (['Receita', 'Despesa'].includes(params?.type)) {
  //       filters = {
  //         ...filters,
  //         ...params,
  //         billing_type_generic: params?.type || 'Despesa',
  //         type: params?.type,
  //       }
  //     } else {
  //       filters = {
  //         ...filters,
  //         ...params,
  //         billing_type_generic: params?.billing_type_generic || 'Despesa',
  //         type: params?.type,
  //       }
  //     }
  //   }

  //   if (location?.state?.billing_type_generic) {
  //     filters = {
  //       ...filters,
  //       billing_type_generic: location?.state?.billing_type_generic,
  //       type: location?.state?.type || location?.state?.billing_type_generic,
  //       reference_month: location?.state?.reference_month,
  //       period: location?.state?.period,
  //       year: location?.state?.year,
  //       service_id: location?.state?.service_id,
  //     }
  //   }

  //   if (
  //     (filters?.type == 'Receita' ||
  //       filters?.billing_type_generic == 'Receita') &&
  //     value == 0
  //   ) {
  //     setValue(1)
  //   }

  //   if (
  //     (filters?.type == 'received' ||
  //       filters?.billing_type_generic == 'received') &&
  //     value == 0
  //   ) {
  //     setValue(1)
  //   }

  //   setForm(prev => ({
  //     ...prev,
  //     ...filters,
  //   }))
  // }, [location])

  // useEffect(() => {
  //   dispatch(fetchBilling(form))
  // }, [form])

  // useEffect(() => {
  //   setForm({
  //     ...form,
  //     type: typeGeneric,
  //     billing_type_generic: typeGeneric,
  //   })
  // }, [])

  return (
    <>
      {[0, 1, 2].includes(value) ? (
        <>
          <Grid
            container
            // className={classes.pageHead}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={12}>
              <BaseCreatePage
                page="Financeiro"
                path="Lançamentos"
                pageLink="/billing"
                hiddenReturn
                goBack={params}
              />
            </Grid>

            <Grid item xs={12}>
              <Tabs>
                <Tab
                  label="Despesas"
                  value={value}
                  tabIndex={0}
                  setValue={setValue}
                  icon={mdiCurrencyUsdOff}
                  iconColor={colors.error}
                  removeNavigation={false}
                />
                <Tab
                  label="Receitas"
                  value={value}
                  tabIndex={1}
                  setValue={setValue}
                  icon={mdiCurrencyUsd}
                  iconColor={colors.sucess}
                  removeNavigation={false}
                />
                <Tab
                  label="Extrato Bancário"
                  value={value}
                  setValue={setValue}
                  tabIndex={2}
                  icon={mdiFileDocumentCheckOutline}
                  iconColor={colors.primary}
                  removeNavigation={false}
                />
              </Tabs>

              {value === 0 && (
                <Grid container spacing={3} style={{ marginTop: '1em' }}>
                  <Grid item xs={12}>
                    <Filter
                      permissions={permissions}
                      form={form}
                      setForm={setForm}
                      typeGeneric="Despesa"
                      location={location}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <BalanceList
                      data={items || []}
                      permissions={permissions}
                      typeGeneric="Despesa"
                    />
                  </Grid>
                </Grid>
              )}

              {value === 1 && (
                <Grid container spacing={3} style={{ marginTop: '1em' }}>
                  <Grid item xs={12}>
                    <Filter
                      permissions={permissions}
                      typeGeneric="Receita"
                      location={location}
                      form={form}
                      setForm={setForm}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <BalanceList
                      type="revenues"
                      data={items || []}
                      permissions={permissions}
                      typeGeneric="Receita"
                    />
                  </Grid>
                </Grid>
              )}

              {value === 2 && (
                <Grid container spacing={3} style={{ marginTop: '1em' }}>
                  <Grid item xs={12}>
                    <BankReportFilter />
                  </Grid>

                  <Grid item xs={12}>
                    <BankTable historic={historic} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {renderRoute(['create-billing'], permissions) && (
            <Route path="/billing/new" component={New} />
          )}

          {renderRoute(['update-billing'], permissions) && (
            <Route path="/billing/:id/edit" component={Edit} />
          )}

          {renderRoute(['read-billing'], permissions) && (
            <Route path="/billing/:id/close" component={CloseDetails} />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Billing
