import { FETCH_LAUNDRY_DASHBOARD } from './ItemsReportActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  laundries: [],
  statuses: [],
  //   totalSize: 0,
  //   page: 1,
  //   sizePerPage: 10,
  //   filters: {},
  //   editingLaundry: null,
  //   itemHistoric: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY_DASHBOARD.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        meta: action.payload.data.meta,
        laundries: action.payload.data.laundries,
        statuses: action.payload.data.statuses,
      }
    default:
      return state
  }
}

// export const getLaundryListData = state => {
//   return {
//     items: state.laundryDashboard.items,
//     page: state.laundryDashboard.page,
//     sizePerPage: state.laundryDashboard.sizePerPage,
//     totalSize: state.laundryDashboard.totalSize,
//   }
// }
