import React from 'react'
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import colors from '../../../../../assets/colors'
import Icon from '@mdi/react'
import { mdiCurrencyUsd } from '@mdi/js'
import CountUp from 'react-countup'

const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.graylight}`,
    padding: '8px 16px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    color: colors.primary,
  },
  paper: {
    color: colors.primary,
    fontSize: '12px',
  },
  number: {
    fontWeight: '700',
    fontSize: '20px',
  },
  value: {
    fontWeight: '700',
    fontSize: '12px',
  },
})

const CardServiceType = ({ name, qtd, orcament, unitQtd, measureType }) => {
  const classes = useStyles()

  const contUp = (money, decimals = 2) => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={decimals}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Grid item sm={4} lg={3} md={4} xs={12}>
      <Box className={classes.root}>
        <Box
          style={{
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: `1px solid ${colors.graylight}`,
          }}
        >
          <Typography style={{ textTransform: 'capitalize' }}>
            {name}
          </Typography>
          <Box
            style={{
              display: 'flex',
              gap: '4px',
              fontWeight: 'bold',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {contUp(orcament)}
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            borderBottom: `1px dashed ${colors.graylight}`,
            paddingBottom: '6px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              fontSize: '12px',
            }}
          >
            <Typography style={{ lineHeight: '13px' }}>
              Total de itens em <br />
              Serviços
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>{qtd}</Typography>
          </Box>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Typography>
            {unitQtd} {measureType}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default CardServiceType
