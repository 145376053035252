import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors as MUIColors,
  makeStyles,
  Box,
} from '@material-ui/core'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import classnames from 'classnames'
import CountUp from 'react-countup'

import { useSelector } from 'react-redux'
import colors from '../../../../../assets/colors'
import { getDecimalPart } from '../../../../../util/utils'

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    height: '100%',
  },
  differenceValue: {
    color: MUIColors.green[900],
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: MUIColors.red[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: MUIColors.grey[900],
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '20px',
    color: colors.primary,
    lineHeight: '20px',
    fontWeight: 'normal',
    padding: '8px 0 0 8px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px',
    fontSize: '22px',
  },
  bodyValue: {
    fontSize: '32px',
    color: colors.graylight,

    fontWeight: '700',
  },
  footerBox: {
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  footer: {
    height: '28px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: colors.primary,
    padding: '0 22px 0 8px',
    borderRadius: '0px 0px 10px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 6,
    color: 'white',
  },
  footerValue: {
    height: '28px',
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  centsFooter: {
    fontSize: '12px',
    fontWeight: '400',
    padding: 0,
  },
  positive: {
    color: colors.sucess,
  },
  negative: {
    color: colors.error,
  },
}))

const SummaryCard = ({ total, tipo, today, isMoney, decimals = 0 }) => {
  const classes = useStyles()

  const contUp = (money, prefix = '') => {
    return (
      <CountUp
        end={money}
        duration={0.8}
        decimal=","
        prefix={prefix}
        separator="."
      />
    )
  }

  const contUpDecimals = money => {
    if (money === 0) return ',00'
    const decimalPart = getDecimalPart(money)
    return <>{',' + decimalPart}</>
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{tipo}</Box>

      <Box className={classes.body}>
        <span
          className={classnames({
            [classes.bodyValue]: true,
            [classes.positive]: total > 0,
            [classes.negative]: total < 0,
          })}
        >
          {contUp(total, isMoney && 'R$')}
          {isMoney ||
            (decimals ? (
              <span
                className={classes.centsFooter}
                style={{ fontSize: '22px' }}
              >
                {contUpDecimals(today)}
              </span>
            ) : (
              ''
            ))}
        </span>
      </Box>

      <Box className={classes.footerBox}>
        <Box className={classes.footer}>
          <span>Hoje:</span>
          {isMoney && <Typography>R$</Typography>}
          <span className={classes.footerValue}>
            {contUp(today)}
            {isMoney || decimals ? (
              <span className={classes.centsFooter}>
                {contUpDecimals(today)}
              </span>
            ) : (
              ''
            )}
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default SummaryCard
