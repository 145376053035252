import React, { useEffect, useState } from 'react'

import { Box, Grid } from '@material-ui/core'
import Tabs from '../../../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../../../components/hooks/BaseTabs/Tab'
import {
  mdiAccountEdit,
  mdiAccountGroup,
  mdiCommentTextMultiple,
  mdiDatabaseEdit,
  mdiFaceAgent,
  mdiPhoneClock,
  mdiWater,
} from '@mdi/js'
import GeneralForm from './Forms/General'
import AttendancesForm from './Forms/Attendances'
import AppointmentForm from './Forms/Appointment'
import LastAppointmentsForm from './Forms/LastAppointments'

const Form = ({
  edit = false,
  mutate = {},
  editingId = null,
  readOnly = false,
}) => {
  const [value, setValue] = useState(0)

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Tabs>
            <Tab
              tabIndex={0}
              value={value}
              setValue={setValue}
              icon={mdiDatabaseEdit}
              label="Dados Gerais"
              removeNavigation={false}
            />
            <Tab
              tabIndex={1}
              value={value}
              setValue={setValue}
              icon={mdiAccountGroup}
              label="Técnicos"
              removeNavigation={!edit && true}
            />
            <Tab
              tabIndex={2}
              value={value}
              setValue={value => {
                if (readOnly) return

                setValue(value)
              }}
              icon={mdiPhoneClock}
              label="Agendamento Avulso"
              removeNavigation={edit ? (readOnly ? true : false) : true}
            />
            <Tab
              tabIndex={3}
              value={value}
              setValue={setValue}
              icon={mdiFaceAgent}
              label="Ultimos Agendamentos"
              removeNavigation={!edit && true}
            />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {value == 0 && (
            <GeneralForm edit={edit} mutate={mutate} readOnly={readOnly} />
          )}
          {value == 1 && (
            <AttendancesForm
              mutate={mutate}
              editingId={editingId}
              readOnly={readOnly}
            />
          )}
          {value == 2 && (
            <AppointmentForm editingId={editingId} mutate={mutate} />
          )}
          {value == 3 && <LastAppointmentsForm editingId={editingId} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Form
