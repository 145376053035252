/* eslint-disable import/order */
import React from 'react'
import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight, mdiMessageQuestion } from '@mdi/js'
import colors from '../../../assets/colors'
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  root: {
    animation: '$fadeIn 1s ease-in-out',
  },
  pageLink: {
    width: 'auto',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  page: {
    fontSize: '14px',
    color: colors.textGray,
    fontWeight: 'normal',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  path: {
    fontSize: '20px',
    color: colors.primary,
    fontWeight: '700',
    position: 'relative',
  },
})

const BaseCreatePage = ({
  page,
  pageLink,
  path,
  helperLink,
  hiddenReturn = false,
  goBack = false,
  children,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid className={classes.root} spacing={4} direction="column">
      <Grid item container xs={12}>
        <Grid item xs={6} container direction="row" alignItems="center">
          <IconButton
            component={Link}
            to={pageLink}
            style={{ padding: 0 }}
            className={classes.pageLink}
          >
            <Typography variant="h6" color="inherit" className={classes.page}>
              {page}
            </Typography>
          </IconButton>

          <Icon path={mdiChevronRight} size={1} color={colors.textPrimary} />

          <Typography variant="h6" color="inherit" className={classes.path}>
            {path}

            <IconButton className={classes.questionBox}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a href={helperLink} target="_blank" rel="noreferrer">
                <Icon
                  path={mdiMessageQuestion}
                  size={1}
                  color={colors.secondary}
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '-10px',
                  }}
                />
              </a>
            </IconButton>
          </Typography>
        </Grid>

        {!hiddenReturn && (
          <Grid item xs={6} container display="flex" justifyContent="flex-end">
            <IconButton
              component={Link}
              to={pageLink}
              className={classes.pageLink}
            >
              <Icon path={mdiChevronLeft} size={1} color={colors.textPrimary} />

              <Typography variant="h6" color="inherit" className={classes.page}>
                Voltar
              </Typography>
            </IconButton>
          </Grid>
        )}

        {goBack && (
          <Grid item xs={6} container display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => history.goBack()}
              className={classes.pageLink}
            >
              <Icon path={mdiChevronLeft} size={1} color={colors.textPrimary} />

              <Typography variant="h6" color="inherit" className={classes.page}>
                Voltar
              </Typography>
            </IconButton>
          </Grid>
        )}

        {children}
      </Grid>
    </Grid>
  )
}

export default BaseCreatePage
