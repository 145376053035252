/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../components/hooks/BaseTableComponents'
import {
  mdiAlert,
  mdiClipboardTextClockOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiNoteEditOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../../assets/colors'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLaundries, remove } from '../PartnersActions'
import Pagination from '../../../../components/hooks/BasePagination'

const useStyles = makeStyles(theme => ({
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
  missingProduct: {
    color: colors.error,
    gap: '.4em',
  },
  tooltip: {
    backgroundColor: colors.error,
    fontSize: '14px',
    boxShadow: '0px 0px 7px 0px #00000040',
    '& .arrow': {
      color: colors.error,
    },
  },
}))

const List = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const items = useSelector(state => state.laundries.items)
  const meta = useSelector(state => state.laundries.meta)
  const filters = useSelector(state => state.laundries.filters)

  const [open, setOpen] = useState({
    id: '',
  })

  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    setPaginate({
      ...paginate,
      page: newPage,
    })
  }

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const confirmDelete = (id, name) => {
    confirm({
      description: `Deseja deletar a lavanderia: ${name}?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  useEffect(() => {
    const form = {
      ...filters,
      ...paginate,
    }

    dispatch(fetchLaundries(form))
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left">ID</StyledTableCellHeader>
              <StyledTableCellHeader align="left">Nome</StyledTableCellHeader>
              <StyledTableCellHeader align="left">
                Responsável
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">E-mail</StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Ações
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(laundry => (
              <TableRow key={laundry?.id} hover>
                <StyledTableCellBody>{laundry?.id}</StyledTableCellBody>

                <StyledTableCellBody>{laundry?.name}</StyledTableCellBody>
                <StyledTableCellBody>
                  {laundry?.contact_name}
                </StyledTableCellBody>
                <StyledTableCellBody>{laundry?.email}</StyledTableCellBody>
                <StyledTableCellBody align="center">
                  <IconButton
                    ref={el => (anchorRefs.current[laundry?.id] = el)}
                    key={laundry.id}
                    id={laundry?.id}
                    aria-controls={
                      open?.id ? `menu-list-grow-${laundry.id}` : undefined
                    }
                    aria-haspopup="true"
                    onClick={() => {
                      handleToggle(laundry.id)
                    }}
                  >
                    <Icon path={mdiDotsVertical} size={1} />
                  </IconButton>

                  <Popper
                    open={open?.id === laundry?.id}
                    role={undefined}
                    anchorEl={anchorRefs.current[laundry?.id]}
                    transition
                    disablePortal
                    style={{
                      zIndex: 99999999,
                    }}
                    placement="bottom-end"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement == 'bottom' ? 'left-end' : 'top-end',
                        }}
                      >
                        <Paper className={classes.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open?.id}
                              id={`menu-list-grow-${laundry.id}`}
                              onKeyDown={handleListKeyDown}
                              className={classes.menuList}
                            >
                              <MenuItem
                                className={classes.ref}
                                style={
                                  placement === 'bottom-end'
                                    ? { top: '-2px' }
                                    : { bottom: '-2px' }
                                }
                              />
                              <MenuItem
                                onClick={handleClose}
                                className={classes.menuItem}
                              >
                                <IconButton
                                  component={Link}
                                  to={`/partners-module/partner/visualization/${laundry?.id}`}
                                  className={classes.menuItemLi}
                                >
                                  <Icon
                                    path={mdiEyeOutline}
                                    size={1}
                                    className={classes.menuItemLiIcon}
                                  />
                                  Visualizar
                                </IconButton>
                              </MenuItem>

                              <MenuItem
                                onClick={handleClose}
                                className={classes.menuItem}
                              >
                                <IconButton
                                  component={Link}
                                  to={`/partners-module/partner/edit/${laundry?.id}`}
                                  className={classes.menuItemLi}
                                >
                                  <Icon
                                    path={mdiNoteEditOutline}
                                    size={1}
                                    className={classes.menuItemLiIcon}
                                  />
                                  Editar
                                </IconButton>
                              </MenuItem>

                              <MenuItem
                                onClick={handleClose}
                                className={classes.menuItem}
                              >
                                <IconButton
                                  className={classes.menuItemLi}
                                  onClick={() =>
                                    confirmDelete(laundry?.id, laundry?.name)
                                  }
                                >
                                  <Icon
                                    path={mdiDelete}
                                    size={1}
                                    className={classes.menuItemLiIcon}
                                  />
                                  Excluir
                                </IconButton>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination
          from={meta?.from}
          to={meta?.to}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={paginate?.per_page}
          rows={meta?.total}
          page={paginate?.page}
          handleChangePage={handleChangePage}
        />
      </TableContainer>
    </>
  )
}

export default List
