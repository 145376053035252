import { FETCH_DASHBOARD } from './PartnersModuleActions'

const INITIAL_STATE = {
  data: [],
  laundries: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        laundries: action.payload.laundries,
      }
    default:
      return state
  }
}
