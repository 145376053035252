import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EventIcon from '@material-ui/icons/Event'

import consts from '../../../../util/consts'
import { getSorting, stableSort } from '../../../../util/TableUtils'
import { exportFormatedMoney, renderRoute } from '../../../../util/utils'
import Paginate from '../../../components/Pagination'
import EnhancedTableHead from '../../../components/TableHead'
import { fetchServiceItems } from '../ItemsReportActions'
import { StyledTableCellBody } from '../../../../components/hooks/BaseTableComponents'
// import Pagination from '../../../../components/Pagination'
import Pagination from '../../../../components/hooks/BasePagination'
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiDotsVertical, mdiEyeOutline, mdiHistory } from '@mdi/js'
import colors from '../../../../assets/colors'
// import { clearFilters, fetchDashboard } from '../LaundryDashboardActions'

const headCells = [
  {
    id: 'id',
    label: 'Nº da OS',
  },
  {
    id: 'bar_code',
    label: 'Etiqueta',
  },
  {
    id: 'laundry',
    label: 'Lavanderia',
  },
  {
    id: 'value',
    label: 'Valor',
  },
  {
    id: 'collected_at',
    label: 'Coletado em',
  },

  {
    id: 'delivered_at',
    label: 'Entregue em',
  },
  {
    id: 'type',
    label: 'Tipo',
  },
  {
    id: 'measures',
    label: 'Medidas',
  },
  {
    id: 'quantity',
    label: 'Quantidade',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'rework',
    label: 'Retrabalho',
  },
  {
    id: 'delivery',
    label: 'Delivery',
  },
  {
    id: 'actions',
    label: 'Ações',
  },
]

const useStyles = makeStyles({
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
})

function List(props) {
  const { classes } = props

  const dispatch = useDispatch()
  const tableClasses = useStyles()

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('')
  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 10,
  })
  const [open, setOpen] = useState({
    id: '',
  })

  const items = useSelector(state => state.laundryItemsReport.items)
  const meta = useSelector(state => state.laundryItemsReport.meta)
  const filters = useSelector(state => state.laundryItemsReport.filters)

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    console.log(newPage)
    setPaginate({
      ...paginate,
      page: newPage,
    })
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const openHistoryItem = id => {
    setItem(id)
    handleItemHistoryOpen()
  }

  useEffect(() => {
    const form = {
      ...filters,
      ...paginate,
    }

    dispatch(fetchServiceItems(form))
  }, [paginate])

  return (
    <>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Attendance table"
          size="small"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={items.length}
            headCells={headCells}
            renderFirstTH={false}
          />
          <TableBody>
            {stableSort(items, getSorting(order, orderBy)).map(item => {
              return (
                <TableRow key={item.id} hover>
                  <StyledTableCellBody>
                    {item.id_laundry_client}
                  </StyledTableCellBody>
                  <StyledTableCellBody>{item.bar_code}</StyledTableCellBody>
                  <StyledTableCellBody>{item.laundryName}</StyledTableCellBody>
                  <StyledTableCellBody>
                    {exportFormatedMoney(item.final_value)}
                  </StyledTableCellBody>
                  <StyledTableCellBody>{item.collected_at}</StyledTableCellBody>

                  <StyledTableCellBody>{item.delivered_at}</StyledTableCellBody>
                  <StyledTableCellBody>
                    {item.service_type?.name}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {item.service_type?.measure_type?.name}
                  </StyledTableCellBody>
                  <StyledTableCellBody>{item.quantity}</StyledTableCellBody>
                  <StyledTableCellBody>{item.status}</StyledTableCellBody>
                  <StyledTableCellBody>
                    {item.is_rework ? 'Sim' : 'Não'}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {item.is_delivery ? 'Sim' : 'Não'}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    <IconButton
                      ref={el => (anchorRefs.current[item?.id] = el)}
                      key={item.id}
                      id={item?.id}
                      aria-controls={
                        open?.id ? `menu-list-grow-${item.id}` : undefined
                      }
                      aria-haspopup="true"
                      onClick={() => {
                        handleToggle(item.id)
                      }}
                    >
                      <Icon path={mdiDotsVertical} size={1} />
                    </IconButton>

                    <Popper
                      open={open?.id === item?.id}
                      role={undefined}
                      anchorEl={anchorRefs.current[item?.id]}
                      transition
                      disablePortal
                      style={{
                        zIndex: 99999999,
                      }}
                      placement="bottom-end"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement == 'bottom' ? 'left-end' : 'top-end',
                          }}
                        >
                          <Paper className={tableClasses.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open?.id}
                                id={`menu-list-grow-${item.id}`}
                                onKeyDown={handleListKeyDown}
                                className={tableClasses.menuList}
                              >
                                <MenuItem
                                  onClick={handleClose}
                                  className={tableClasses.menuItem}
                                >
                                  <IconButton
                                    component={Link}
                                    to={`/service-item/history/${item.id}`}
                                    className={tableClasses.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiHistory}
                                      size={1}
                                      className={tableClasses.menuItemLiIcon}
                                    />
                                    Histórico
                                  </IconButton>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </StyledTableCellBody>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        from={meta?.from}
        to={meta?.to}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={paginate?.per_page}
        rows={meta?.total}
        page={paginate?.page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export default List
