import {
  Box,
  createTheme,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  ThemeProvider,
} from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'

const useStyles = makeStyles({
  box: {
    minHeight: '42px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '48px',
    justifyContent: 'flex-end',
  },
})

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        '&:before': {
          borderBottom: 'none !important',
          border: 'none !important',
          outline: 'none !important',
        },
        '&:focus': {
          borderBottom: 'none !important',
          border: 'none !important',
          outline: 'none !important',
        },
      },
    },
  },
})

// Refatorar o codigo para a paginacao comecar da pagina 1
const Pagination = ({
  rows,
  page = 1,
  rowsPerPage = 10,
  from = 1,
  to = 10,
  handleChangeRowsPerPage = () => {},
  handleChangePage = () => {},
}) => {
  const classes = useStyles()

  const handleClickChangePage = (e, type) => {
    if (type == 'prev') {
      handleChangePage(e, Number(page - 1))
    }

    if (type == 'next') {
      handleChangePage(e, Number(page + 1))
    }
  }

  return (
    <Box className={classes.box}>
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <span>Linhas Por Página</span>
        <ThemeProvider theme={theme}>
          <TextField
            variant="standard"
            select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
          </TextField>
        </ThemeProvider>
      </div>

      <span>
        Mostrando linhas {from}-{to} de {rows}
      </span>

      <div className={classes.areaButtons}>
        <IconButton
          disabled={page == 1}
          onClick={e => handleClickChangePage(e, 'prev')}
        >
          <Icon path={mdiChevronLeft} size={1} />
        </IconButton>
        <IconButton
          disabled={to >= rows}
          onClick={e => handleClickChangePage(e, 'next')}
        >
          <Icon path={mdiChevronRight} size={1} />
        </IconButton>
      </div>
    </Box>
  )
}

export default Pagination
