import axios from 'axios/index'

import config from '../../../config'
import action, { actionFailed } from '../../../util/action'
import createAsyncConst from '../../../util/createAsyncConst'

export const REMOVE_LAUNDRY = createAsyncConst('REMOVE_LAUNDRY')
export const SUBMIT_LAUNDRY = createAsyncConst('SUBMIT_LAUNDRY')
export const FETCH_LAUNDRY = createAsyncConst('FETCH_LAUNDRY')
export const FETCH_LAUNDRY_INIT = createAsyncConst('FETCH_LAUNDRY_INIT')
export const FETCH_LAUNDRY_FILTERS = createAsyncConst('FETCH_LAUNDRY_FILTERS')
export const EDIT_LAUNDRY = createAsyncConst('EDIT_LAUNDRY')
export const NEW_DETACHED = createAsyncConst('NEW_DETACHED')
export const GET_DETACHEDS = createAsyncConst('GET_DETACHEDS')
export const REMOVE_DETACHEDS = createAsyncConst('REMOVE_DETACHEDS')
export const GET_REPORT = createAsyncConst('GET_REPORT')

// new consts
export const SYNC_ATTENDANTS = createAsyncConst('SYNC_ATTENDANTS')

export function fetchLaundries(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY.ACTION))
    return axios
      .get(`${config.API_URL}/laundry`, {
        params: filters,
      })
      .then(resp => {
        dispatch(
          action(FETCH_LAUNDRY.SUCCESS, { data: resp.data, filters: filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY, e)))
  }
}

export function fetchLaundriesInit(filters = { per_page: 1 }) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY.ACTION))
    return axios
      .get(`${config.API_URL}/laundry`, {
        params: filters,
      })
      .then(resp => {
        dispatch(action(FETCH_LAUNDRY.SUCCESS, { data: resp.data }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_LAUNDRY.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/laundry/${id}`)
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(EDIT_LAUNDRY.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_LAUNDRY, e)))
  }
}

function submit(values, method, id = null) {
  return dispatch => {
    dispatch(action(SUBMIT_LAUNDRY.ACTION))
    return axios[method](
      `${config.API_URL}/laundry${id ? `/${id}` : ''}`,
      values
    )
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_LAUNDRY.SUCCESS, data))
        dispatch(fetchLaundries())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_LAUNDRY, e)))
  }
}
export function post(values) {
  return submit(values, 'post')
}

export function update(values, id) {
  return submit(values, 'put', id)
}

export function syncAttendants(data, id) {
  return dispatch => {
    dispatch(action(SYNC_ATTENDANTS.ACTION))
    return axios
      .put(`${config.API_URL}/laundry/sync-attendants/${id}`, data)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SYNC_ATTENDANTS.SUCCESS, data))
        dispatch(edit(id))
        return data
      })
      .catch(e => dispatch(actionFailed(SYNC_ATTENDANTS, e)))
  }
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_LAUNDRY.ACTION))
    return axios
      .delete(`${config.API_URL}/laundry/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_LAUNDRY.SUCCESS, data))
        dispatch(fetchLaundries())
      })
      .catch(e => dispatch(actionFailed(REMOVE_LAUNDRY, e)))
  }
}

export function newAppointment(data, id) {
  return dispatch => {
    dispatch(action(NEW_DETACHED.ACTION, { id }))
    return axios
      .post(`${config.API_URL}/laundry/new-appointment/${id}`, data)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(NEW_DETACHED.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(NEW_DETACHED, e)))
  }
}

export function getAppointments(id) {
  return dispatch => {
    dispatch(action(GET_DETACHEDS.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/laundry/appointments/${id}`)
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(GET_DETACHEDS.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(GET_DETACHEDS, e)))
  }
}

export function removeAppointments(id) {
  return dispatch => {
    dispatch(action(REMOVE_DETACHEDS.ACTION, { id }))
    return axios
      .delete(`${config.API_URL}/laundry/remove-appointment/${id}`)
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(REMOVE_DETACHEDS.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(REMOVE_DETACHEDS, e)))
  }
}

export function makeReport(filters) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/laundry/laundry-registry-report`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        filters: JSON.stringify(filters),
      },
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'laundry_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(GET_REPORT, e)))
  }
}
