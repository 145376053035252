/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../../../resources/theme/global'
import { Box, Fade, Grid, makeStyles, Slide } from '@material-ui/core'
import BaseCreatePage from '../../../../../components/hooks/BaseCreatePage'
import colors from '../../../../../assets/colors'

import Form from './Form'
import { fetchLaundriesInit } from '../../PartnersActions'

const useStyles = makeStyles({
  rowMessage: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  message: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 'auto',
    fontWeight: 'normal',
  },
  btnCalendar: {
    color: '#FFFF',
    fontSize: '14px',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    padding: '4px 12px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: colors.primary,
    },
  },
})

const NewLaundry = props => {
  const { history } = props
  const classes = useStyles()

  const permissions = useSelector(state => state.auth.permissions)
  const paymentMethods = useSelector(state => state.laundries.paymentMethods)

  const dispatch = useDispatch()

  useEffect(() => {
    if (paymentMethods?.length) return

    dispatch(fetchLaundriesInit())
  }, [paymentMethods])

  return (
    <BaseCreatePage
      page="Parceiros"
      path="Cadastro"
      pageLink="/partners-module/partners"
    >
      <Grid
        container
        xs={12}
        alignContent="center"
        justifyContent="flex-start"
        className={classes.rowMessage}
      >
        <Grid item className={classes.message}>
          Utilize os campos abaixo para cadastrar uma nova lavanderia
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: '1em' }}>
        <Form />
      </Grid>
    </BaseCreatePage>
  )
}

export default withStyles(styles)(NewLaundry)
